import React, { useEffect, useState } from 'react'

import { timeStringInScheduler } from '../../../functions/agent/date/timeStringInScheduler'
import { useAppDispatch } from '../../../hooks/redux'
import { type IUnitKey, type Unit } from '../../../store/slices/unitsSlice'
import { type IViewing } from '../../../store/slices/viewingsSlice'

import { SchedulerStatus } from '../../../functions/agent/date/SchedulerStatus'

import useUserType from '../../../hooks/UseUserType'
import AgentInfoForChildren from './contactInfo/AgentInfoForChildren'
import ViewingTimeline from './ViewingTimeline'
import { USER_ID_KEY } from '../../../urls'
import { viewViewing } from '../../../store/actionsCreators/viewingActions/viewViewing'
import { getBelongsUnitForViewing } from '../../../functions/shared/schedule/getBelongsUnit'
import CallToBSO from './unitsActions/CallToBSO'


interface IProps {
    currentUnit: Unit
    currentViewing: IViewing[]

}

export default function ChildViewing({ currentViewing, currentUnit }: IProps): JSX.Element {
    const { userType } = useUserType()
    const dispatch = useAppDispatch()

    const userID = window.localStorage.getItem(USER_ID_KEY)
    const key = (currentViewing?.[0])?.attributes?.KeyBooking?.data as IUnitKey
    let DropOutDate = ''; let PickUpDate = ''
    if (key !== null) {
        DropOutDate = key?.attributes?.DropOutDate
        PickUpDate = key?.attributes?.PickUpDate
    }

    const unit = getBelongsUnitForViewing(currentViewing?.[0])
    const unitStatuses = currentUnit?.attributes?.Statuses
    const viewingStatuses = currentViewing?.[0]?.attributes?.Statuses
    useEffect(() => {
        const landlordViewStatuses = ['Waiting for feedback', 'Waiting for complain', 'Completed', 'Approved', 'Canceled']
        const agentViewStatuses = ['Waiting for feedback', 'Rescheduled by agent', 'Completed', 'Rejected', 'Rescheduled by landlord', 'Completed', 'Approved', 'Canceled'] // 'Rescheduled by BSO',
        const otherAgentViewStatuses = ['Approved', 'Completed', 'Canceled']

        if (userType === 'Agent' && (currentViewing?.[0]?.attributes?.Notify === 'Agent') && unit.type !== 'MY UNIT' && agentViewStatuses.includes(viewingStatuses?.[0] ?? '')) {
            dispatch(
                viewViewing({
                    viewID: currentViewing[0].id,
                    notify: null,
                    view: currentUnit
                })
            )
        } else if (userType === 'Agent' && (currentViewing?.[0]?.attributes?.Notify === 'Other Agent') && unit.type === 'MY UNIT' && otherAgentViewStatuses.includes(viewingStatuses?.[0] ?? '')) {
            dispatch(
                viewViewing({
                    viewID: currentViewing[0].id,
                    notify: null,
                    view: currentUnit
                })
            )
        }

        if (currentViewing?.[0]?.attributes?.Notify === 'Both') {
            if (userType === 'Agent') {
                dispatch(
                    viewViewing({
                        viewID: currentViewing[0].id,
                        notify: 'BSO',
                        view: currentUnit
                    })
                )
            } else {
                dispatch(
                    viewViewing({
                        viewID: currentViewing[0].id,
                        notify: 'Agent',
                        view: currentUnit
                    })
                )
            }
        }

        if (userType === 'Landlord' && currentViewing?.[0]?.attributes?.Notify === 'BSO' && landlordViewStatuses.includes(viewingStatuses?.[0] ?? '')) {
            dispatch(
                viewViewing({
                    viewID: currentViewing[0].id,
                    notify: null,
                    view: currentUnit
                })
            )
        }
    }, [])

    const [viewingKey, setViewingKey] = useState(0)

    useEffect(() => {
        setViewingKey((prevKey) => prevKey + 1)
    }, [currentViewing])






    return (
        <>
            <div className="fv-row mb-2 m-0 p-0 w-100">
                <div className="d-flex align-items-center">
                    <div className="fs-3 fw-bolder text-dark mb-4 lh-1">   Viewing Information</div>
                    {currentViewing[0]?.attributes?.Datetime
                        ? <div className="fv-row mb-2 m-0 p-0 w-100 text-end">
                            <label className="form-label fs-5 fw-bold text-dark m-0">
                                Viewing date
                            </label>
                            <div className="fw-normal text-dark mb-2">
                                <div className="badge badge-light-primary border border-primary fs-6 mb-1">{timeStringInScheduler(currentViewing[0]?.attributes?.Datetime) ?? ''}</div>
                            </div>
                        </div>
                        : null}
                </div>
                <ViewingTimeline key={viewingKey} viewing={currentViewing[0]} unit={currentUnit} unitKey={key} isMyUnit={currentUnit?.attributes?.User?.data?.id === Number(userID)} />
            </div >




            {key !== null && key?.attributes?.Status?.includes('Completed')
                ? <>

                    <div className="fv-row mb-2 m-0 p-0 w-100">
                        <label className="form-label fw-bold text-dark m-0 fs-5">
                            Time history keys
                        </label>
                        <div className="fw-normal text-dark">
                            {userType === 'Agent' ? 'You took the ' : 'Agent took the '}
                            keys <strong>{timeStringInScheduler(PickUpDate).split(',')[0]} - {timeStringInScheduler(PickUpDate).split(',')[1]}</strong>
                        </div>
                        <div className="fw-normal text-dark">
                            {userType === 'Agent' ? 'You handed in the ' : 'Agent handed in the '}
                            keys <strong>{timeStringInScheduler(DropOutDate).split(',')[0]} - {timeStringInScheduler(DropOutDate).split(',')[1]}</strong>
                        </div>
                    </div>

                    <label className="form-label fw-bold text-dark m-0 fs-5">
                        Keys status
                    </label>
                    <div className="fw-normal text-dark mb-2">
                        {SchedulerStatus([key?.attributes?.Status ?? ''] ?? null)}
                    </div>
                </>
                : null
            }
            {key !== null && key?.attributes?.Status?.includes('Canceled')
                ? <>

                    <div className="fv-row mb-2 m-0 p-0 w-100">
                        <label className="form-label fw-bold text-dark m-0 fs-5">
                            Time history keys
                        </label>
                        <div className="fw-normal text-dark">
                            {userType === 'Agent' ? 'You should have taken in the' : 'Agent should have taken in the '}
                            keys <strong>{timeStringInScheduler(PickUpDate).split(',')[0]} - {timeStringInScheduler(PickUpDate).split(',')[1]}</strong>
                        </div>
                        <div className="fw-normal text-dark">
                            {userType === 'Agent' ? 'You should have turned in the' : 'Agent should have turned in the'}
                            keys <strong>{timeStringInScheduler(DropOutDate).split(',')[0]} - {timeStringInScheduler(DropOutDate).split(',')[1]}</strong>
                        </div>
                    </div>

                    <label className="form-label fw-bold text-dark m-0 fs-5">
                        Keys status
                    </label>
                    <div className="fw-normal text-dark mb-2">
                        {SchedulerStatus([key?.attributes?.Status ?? ''] ?? null)}
                    </div>
                </>
                : null
            }
            {userType === 'Agent' && unitStatuses?.includes('Special Request') && (viewingStatuses?.includes('In-process') || viewingStatuses?.includes('Approved') || viewingStatuses?.includes('Rescheduled by agent')) &&
                <>
                    <label className="form-label fw-bold text-dark m-0 fs-5">
                        To arrange a viewing of this unit please <strong>call us</strong> for instructions
                    </label>
                    <CallToBSO /></>


            }
            {
                userType === 'Landlord'
                    ? <AgentInfoForChildren userID={currentViewing[0]?.attributes?.User?.data?.id ?? -1} />
                    : <></>
            }
        </>

    )
}
