import React, { useEffect, useState } from 'react'

import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import FormListForMyCustomForView from './FormListForMyCustomForView'

import { AgentModals } from '../../../functions/agent/AgentModals'
import { type RootState } from '../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'

import ChildViewing from './ChildViewing'
import ChildUnit from './ChildUnit'
import fillOfferModal from '../../../functions/agent/modals/fillOfferModal'
import ChildOffer from './ChildOffer'
import ChildAdvertise from './ChildAdvertise'

import { scanDocumentsForOffer } from '../../../store/actionsCreators/offerActions'
import { getOfferType } from '../../../functions/agent/offers/getOfferType'
import { cancelViewing } from '../../../store/actionsCreators/viewingActions/cancelViewing'
import {
    animationOptions,
    mainSwalDeal,
    swalAttentionLeave, swalError,
    swalInProgress,
    swalJustAlertAndRedirect,
    swalNeedLoadDocs, swalNeedRera,
    swalOptions,
    swalRejectedDoc, swalSuccess
} from '../../../functions/swalOptions'
import { isPastDate } from '../../../functions/agent/date/isPastDate'
import { type IClient, type ISettings, type User } from '../../../store/slices/userSlice'
import { type IAdvertise, setIsExtend, setNotEnoughMarketingCredits, setUnitIDnotEnoughAreaForAdvertise } from '../../../store/slices/advertisesSlice'

import swalConfig from '../../texts/swalTexts'
import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { type IViewing } from '../../../store/slices/viewingsSlice'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { createNotifyViewing } from '../../../store/actionsCreators/viewingActions/createNotifyViewing'
import Swal from 'sweetalert2'
import useUserType from '../../../hooks/UseUserType'
import { isInInterval } from '../../../functions/agent/date/isInInterval'
import { USER_AREAS_EXTEND_COUNT, USER_CHOOSE_EXTEND_AREA, USER_ID_KEY } from '../../../urls'
import swalTexts from '../../texts/swalTexts'
import { setButtonDriverAddOnsParent, setButtonDriverExtendLocation, setShowButtonBuyCoinsOnAdvertise, setShowPopupAddOnsAreas, setWhereAfterToGo } from '../../../store/slices/modalSlice'
import { setWhatIsNotThereArea } from '../../../store/slices/areasSlice'
import HideButtonListAddOns from '../../../components/agentModule/addOnsServices/HideButtonListAddOns'
import ChooseAreasList from '../../../components/agentModule/addOnsServices/areas/ChooseAreasList'
import { showFeedbackPopup } from '../../../functions/agent/viewings/showFeedbackPopup'
import { type IUnitKey } from '../../../store/slices/unitsSlice'
import openAddViewingModal from '../../../store/actionsCreators/viewingActions/openAddViewingModal'

import {
    areUnitActionButtonsBlocked
} from '../../../functions/shared/units/getUnitOfferStatus'
import { type IResponseObjectJSON } from '../../../types/userTypes/TAuth'
import { removeLinksAdvertiseAction } from '../../../store/actionsCreators/advertiseActions/removeLinksAdvertiseAction'
import { type IOffer } from '../../../store/slices/offersSlice'
import ImagesPreviewGroup from './previewImage/ImagesPreviewGroup'
import _ from 'lodash'
import CloseIconBasic from '../../../components/shared/elements/CloseIconBasic'
import Icon from '../../icon/Icon'
import { generateAdvertiseLink } from '../../../store/actionsCreators/advertiseActions/createAdvertiseAction'
import {

    offerReactRequireStatuses,
    processOfferSwal
} from '../../../functions/agent/offers/showNegotiateSwals'
import VideoForMyCustomModalForView from './unitsActions/VideoForMyCustomModalForView'
import FloorPlanForMyCustomModalForView from './unitsActions/FloorPlanForMyCustomModalForView'
import CustomModalForViewRenderDescription from './CustomModalForViewRenderDescription'

import { updateStatusOfferAgent } from '../../../store/actionsCreators/offerActions/agentToAgentOfferCommunication/updateStatusOfferAgent'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { optionsSuccess } from '../../../components/agentModule/componentsViewings/ViewingActionsForOtherAgents'
import { updateStatusViewing } from '../../../store/actionsCreators/viewingActions/updateStatusViewing'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'
import { openChatByUserID } from '../../../store/actionsCreators/chatActions'
import { makePositiveOrUnchanged } from '../../../functions/agent/date/makePositiveOrUnchanged'
import ChildBroadcast from './ChildBroadcast'
import { isToday } from '../../../functions/shared/date/isToday'
import GeoForMyCustomModalForView from './unitsActions/GeoForMyCustomModalForView'
import { isPinnedChat } from '../../../components/shared/newChat/chatList'
import { userUpdateClickCount } from '../../../store/actionsCreators/userActions/userUpdateClickCount'
import { getBelongsUnitForOffer, getBelongsUnitForViewing } from '../../../functions/shared/schedule/getBelongsUnit'
import TakeUnitsPortfolio from './unitsActions/TakeUnitsPortfolio'
import { filterVacantDateForAdvertise, getNextAvailableAdvert } from '../../../functions/shared/date/filterVacantDateForAdvertise'
import { updateWaitingList } from '../../../store/actionsCreators/unitActions/updateWaitingList'
import { archiveAgentUnit } from '../../../store/actionsCreators/unitActions/archiveAgentUnit'
import { Drawer } from 'antd'
import AddOnsServiceArea from '../../../components/agentModule/addOnsServices/AddOnsServicesCards/AddOnServicesArea'
import AddOnsServiceBricks from '../../../components/agentModule/addOnsServices/AddOnsServicesCards/AddOnsServiceBricks'

const actionRoutes: Record<string, string> = {
    magic_viewing_modal: 'viewing_actions',
    magic_offer_modal: 'offer_actions',
    magic_advertise_modal: 'advertise_actions'
}

export default function MyCustomModalForView(): JSX.Element {
    const navigate = useNavigate()
    const location = useLocation().pathname
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const { modalID, unitID, objectID, from } = useParams()
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    // params
    const isAgentsUnit = from === 'agent_s_unit'
    const isOtherAgentUnit = from === 'other_agent_s_unit'
    const isMyUnit = from === 'my_unit'

    // TO DO revise cancel offer_action

    // basic states
    const lsUserId = window.localStorage.getItem(USER_ID_KEY)
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser) as User
    const userID = currentUser.id ?? lsUserId

    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const advertises = useAppSelector((state: RootState) => state.advertises.advertises)
    const units = useAppSelector((state: RootState) => state.units.units)
    const viewings = useAppSelector((state: RootState) => state.viewings.viewings)

    // extra flags for modal
    const { userType, userTypeLowercase } = useUserType()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const loading = useAppSelector(state => state.modal.loading)
    const showPopupAddOnsAreas = useAppSelector((state: RootState) => state.modal.showPopupAddOnsAreas)
    const satedRedirect = useAppSelector((state: RootState) => state.modal.whereAfterToGo)
    const buttonDriverAddOnsParent = useAppSelector((state: RootState) => state.modal.buttonDriverAddOnsParent)
    const buttonDriverExtendLocation = useAppSelector((state: RootState) => state.modal.buttonDriverExtendLocation)
    // users doc statuses
    const { statusesApproved, documentsLoaded, documentsInProgress, documentsRejected, documentsExpires } = useAppSelector((state: RootState) => state.agentStates)
    const verifiedStatus = statusesApproved.emiratesApproved || statusesApproved.passportApproved
    const verifiedInProgress = documentsInProgress.emiratesInProgress || documentsInProgress.passportInProgress
    const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.passportLoaded
    const rejSts = documentsRejected.passportReject || documentsRejected.emiratesReject
    const expSts = documentsExpires.emiratesExpire || documentsExpires.passportExpire

    const clients: IClient[] = currentUser?.Clients // useAppSelector((state: RootState) => (state.user.currentUser as User)?.Clients)

    // current collections in this modal
    const currentUnit = units?.filter((el: any) => el.id === Number(unitID))[0]
    const currentOfferRaw = offers?.find((el: any) => el.id === Number(objectID))
    const currentOffer: any = { id: currentOfferRaw?.id, ...currentOfferRaw?.attributes } // currentUser.Offers.find((el: any) => el.id === Number(objectID))
    const currentAdvertise = advertises?.filter((el: IAdvertise) => el.id === Number(objectID))?.[0]
    const typeUnit = currentUnit?.attributes?.Marketing?.Use ?? ""
    const awaitingList = currentUnit?.attributes?.WaitingList



    const chatList = useAppSelector(state => state.chatList.chatList)
    const suppChat = chatList.filter(isPinnedChat)[0]?.userID



    // extra data
    const nameUser = getNameUser(currentUser)
    const unitAddress = getUnitAddress(currentUnit)
    const unitStatuses = currentUnit?.attributes?.Statuses
    const userAreas = ((currentUser.Settings as ISettings[])?.[0]?.Areas ?? []) as string[]
    const unitArea = currentUnit?.attributes?.Property?.data?.attributes?.Area ?? ''
    const modalItem = AgentModals().filter((el: any) => el.id === modalID)

    // advertise logic
    const MIN_MATCHING_ADVERTISEMENTS = currentUnit?.attributes?.AdvertisementSpaces ?? 3
    let isReserved = false;
    let isAdvertiseArea = false
    let isAdvertising = false
    let isReserveOtherAgents = false
    let isSpecialBroker = false
    let advertiseID = 'not'
    let advertiseOwnerID = 0

    if (userAreas.includes(unitArea) || userAreas.includes('All areas')) {
        isAdvertiseArea = true
    }

    const isAdvFind = advertises.find((adv: IAdvertise) => {
        return adv.attributes.User.data?.id === Number(userID) && adv.attributes.Unit.data?.id === currentUnit.id // && !adv.attributes.Statuses?.includes('Completed')
    })

    if (isAdvFind != null) {
        if (isAdvFind?.attributes?.StartDate && isAdvFind.attributes.EndDate) {
            if (isInInterval(isAdvFind.attributes.StartDate, isAdvFind.attributes.EndDate) || !isAdvFind.attributes.Statuses?.includes('Completed')) {
                isAdvertising = true
                advertiseID = String((isAdvFind).id)
            }
        } else {
            isAdvertising = true
            advertiseID = String((isAdvFind).id)

        }
    }

    const matchingAdvertsFromOtherUsers = advertises.filter((adv: IAdvertise) => {
        const stsAds = adv?.attributes?.Statuses;

        return (
            adv.attributes.User.data?.id !== Number(userID) &&
            adv.attributes.Unit.data?.id === currentUnit.id &&
            ![
                'Canceled',
                'In-process',
                'Advertisement closed',
                'Ads removing rejected',
                'Ads removed',
                'Completed'
            ].some(status => stsAds?.includes(status)) &&
            currentUnit?.attributes?.ImagesOnly !== true &&
            typeUnit === 'For sale' &&
            (
                adv?.attributes?.StartDate == null || adv?.attributes?.EndDate == null ||
                isInInterval(adv.attributes?.StartDate, adv.attributes?.EndDate)
            )
        );
    });

    if (matchingAdvertsFromOtherUsers.length >= MIN_MATCHING_ADVERTISEMENTS) {
        isReserveOtherAgents = true
        isReserved = awaitingList?.some((waitingItem) => {
            if (Number(waitingItem.userID) === Number(userID)) {
                advertiseOwnerID = Number(userID);
                return true;
            }
            return false;
        }) || false;
    } else {
        if (awaitingList != null && awaitingList.length > 0) {
            if (awaitingList[0].userID !== Number(userID)) {
                isReserveOtherAgents = true
            }
            isReserved = awaitingList?.some((waitingItem) => {
                if (Number(waitingItem.userID) === Number(userID)) {
                    advertiseOwnerID = Number(userID);
                    return true;
                }
                return false;
            }) || false;
        }
    }



    useEffect(() => {
        if (userAreas?.includes(unitArea) || userAreas?.includes('All areas')) {
            isAdvertiseArea = true
        }
        const isAdvFind = advertises.find((adv: IAdvertise) => {
            return adv.attributes.User.data?.id === Number(userID) &&
                adv.attributes.Unit.data?.id === currentUnit.id &&
                !adv.attributes.Statuses?.includes('Completed')
        })

        if (isAdvFind != null) {
            if (isAdvFind?.attributes?.StartDate && isAdvFind.attributes.EndDate) {
                if (isInInterval(isAdvFind.attributes.StartDate, isAdvFind.attributes.EndDate) || !isAdvFind.attributes.Statuses?.includes('Completed')) {
                    isAdvertising = true
                    advertiseID = String((isAdvFind).id)
                }
            } else {
                isAdvertising = true
                advertiseID = String((isAdvFind).id)
            }
        }
    }, [advertises])

    // viewings logic
    let currentViewing = viewings?.filter((el: any) => el.id === Number(objectID))

    useEffect(() => {
        const updateViewing = viewings?.find((el: any) => el.id === Number(objectID))
        if (updateViewing !== undefined) {
            currentViewing = [updateViewing]
        }
    }, [viewings, objectID, currentViewing])

    // landlord / admin logic
    let modal: any
    let firstModal: any
    let dataSource: any
    let firstDataSource: any = null
    switch (modalID) {
        case 'unit_modal':
            dataSource = currentUnit
            if (userType === 'Landlord') {
                modal = [
                    {
                        ...modalItem?.[0],
                        footer: modalItem?.[0]?.footer
                            ?.filter((row: any) => row.id === 'close')
                            ?.map((row: any) => ({ ...row, withIcon: false }))
                    }
                ]
            } else {
                modal = modalItem
            }

            break
        case 'offer_modal':
        case 'magic_offer_modal':
            firstModal = fillOfferModal(_.cloneDeep(modalItem), +(objectID ?? '-1'), searchParams, dispatch, navigate, currentOffer)
            modal = [{
                ...modalItem[0],
                form: modalItem[0].form.filter(({ id }: { id: string }) => !['residential', 'commercial', 'sale', 'sale-commercial'].includes(id)),
                footer: firstModal?.[0]?.footer
            }]

            dataSource = currentUnit
            firstDataSource = currentOffer?.Type?.[0]

            break
        case 'client_modal':
            modal = modalItem
            dataSource = clients?.find((el: any) => el.id === Number(objectID))
            break
        default:
            dataSource = currentUnit
            modal = modalItem
    }

    const googleMapsLink = currentUnit?.attributes?.Property?.data?.attributes?.GoogleMapsLink ?? null

    const onCancel = async (): Promise<void> => {
        await swalAttentionLeave(swalConfig.attentionLeave.messageCustomModalForView).then(async (result: any) => {
            if (result.isConfirmed) {
                dispatch(cancelViewing({ viewID: objectID, nameUser, unitAddress, ...(unitStatuses?.includes('Door open') && { isOtherAgent: 'Other Agent' }) }))
            }
        })
    }
    const approveHandler = async (): Promise<void> => {
        const request = await dispatch(
            updateStatusViewing({
                viewID: currentViewing[0]?.id,
                status: 'Approved',
                unitID: (currentViewing.at(0) as IViewing).attributes?.Unit?.data?.id,
                nameUser: getNameUser(currentUser),
                unitAdress: getUnitAddress(currentUnit),
                view: currentViewing,
                ...(unitStatuses?.includes('Door open') && { isOtherAgent: 'Other Agent' })
            })
        )

        if (request.success && request.response.data) {
            await createNotifyViewing(request.response.data, [currentUnit])
            await new Promise((resolve) => {
                Swal.fire({
                    ...swalOptions('success'),
                    ...{
                        title: 'Approved!',
                        html: 'Your viewing has been successfully confirmed',
                        showConfirmButton: true,
                        iconHtml: '<div  id="lottie-icon-success></div>',
                        confirmButtonText: 'Got it',
                        didOpen: async () => {
                            const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                            const pathLottie = await import('../../../animations/Success.json')
                            if (lottieContainer != null) {
                                lottie.loadAnimation({
                                    container: lottieContainer,
                                    renderer: 'svg',
                                    ...{ ...animationOptions, animationData: pathLottie.default }
                                })
                            }
                        }
                    }
                }
                ).then((result: any) => {
                    resolve(result)
                }).catch((err) => { console.log(err) })
            })
        }
    }

    const onBlockLoadDocuments = (): void => {
        if (currentUser.id === Number(process.env.REACT_APP_BSO_USER_ID)) {
            return
        }
        void swalNeedLoadDocs().then(async (result: any) => {
            if (result?.isConfirmed) {
                navigate('/agent/modal/update_emirates_front_together/INagentMODULE/to_choose_subscription')
            }
        })
    }

    const dateTimeViewing = currentViewing?.[0]?.attributes?.Datetime
    const statusesViewing = currentViewing?.[0]?.attributes?.Statuses
    const statusesOffers = currentOffer?.Statuses

    const key = (currentViewing?.[0]?.attributes?.KeyBooking?.data) as IUnitKey
    // btns show
    const showAddFeedback = statusesViewing?.includes('Waiting for feedback')
    const showButtonScanQR = key !== null && ((key?.attributes?.Status?.includes('Waiting for agent') && statusesViewing?.includes('Approved')) || (key?.attributes?.Status?.includes('In-process') && (statusesViewing?.includes('Waiting for feedback') || statusesViewing?.includes('Waiting for complain'))))
    const isThereKey = key !== null && (key?.attributes?.Status?.includes('Waiting for agent') || key?.attributes?.Status?.includes('In-process'))
    const showButtonReschedule = !(statusesViewing?.includes('Waiting for feedback') || statusesViewing?.includes('Rescheduled by agent') || statusesViewing?.includes('Waiting for complain') || statusesViewing?.includes('Completed') || statusesViewing?.includes('Canceled') || (statusesViewing?.includes('Approved') && isThereKey))
    const showButtonCancel = !(statusesViewing?.includes('Waiting for feedback') || statusesViewing?.includes('Waiting for complain') || statusesViewing?.includes('Completed') || (statusesViewing?.includes('Rejected')) || statusesViewing?.includes('Canceled') || (dateTimeViewing ? isPastDate(dateTimeViewing) : !dateTimeViewing)) || (statusesViewing?.includes('Rescheduled by BSO') && !dateTimeViewing)
    const showButtonMakeOffer = statusesViewing?.includes('Completed') || statusesViewing?.includes('Waiting for feedback') || statusesViewing?.includes('Waiting for complain') || modalID === 'unit_modal' || (currentUnit?.attributes?.Statuses.includes('Door close') && (statusesViewing?.includes('Completed')))
    const showButtonApproveViewing = statusesViewing?.includes('Rescheduled by BSO') && !(dateTimeViewing ? isPastDate(dateTimeViewing) : !dateTimeViewing) || statusesViewing?.includes('Rescheduled by other agent')
    const showButtonCancelOffer = ((isAgentsUnit || isOtherAgentUnit || isMyUnit) && !statusesOffers?.includes('In-process'))
    const showButtonNegotiateOffer = statusesOffers?.includes('Negotiated by other agent')
    //  const showButtonSendMessage = (isAgentsUnit || isOtherAgentUnit || isMyUnit)


    const showButtonGoToActions =
        !(
            (modalID?.includes('offer_modal') && (statusesOffers?.includes('Canceled by other agent') || statusesOffers?.includes('Canceled') || statusesOffers?.includes('Completed'))) ||
            (modalID?.includes('viewing_modal') && statusesViewing?.includes('Completed'))
        ) && isMyUnit;

    const [openAlert, setOpenAlert] = useState(false)
    const [openAlertAddv, setOpenAlertAddv] = useState(false)

    const onBlockAdvertise = (): void => {
        void swalAttentionLeave(swalTexts.attentionLeave.messageForAgentButtonActions).then(async (result: any) => {
            if (result.isConfirmed) {
                window.localStorage.setItem(USER_AREAS_EXTEND_COUNT, `${currentUnit.id} - 1`)
                dispatch(setIsExtend(false))
                dispatch(setButtonDriverExtendLocation(true))
                dispatch(setUnitIDnotEnoughAreaForAdvertise(currentUnit.id))
                dispatch(setWhatIsNotThereArea(unitArea))
                window.localStorage.setItem(USER_CHOOSE_EXTEND_AREA, unitArea)
            }
        }).catch(console.log)
    }
    const handleLoad = () => {
        window.open(`https://bso.ae/api/app/downloadPDF?id=${String(currentUnit.id)}&type=Unit%20preview&dev=${isDev}`, '_blank')
    };

    useEffect(() => {
        if (openAlert) {
            if (!verifiedStatus && !verifiedInProgress && userType === 'Agent') {
                if (rejSts) {
                    void swalRejectedDoc(`Your ${documentsRejected.emiratesReject ? 'EmiratesID' : 'Passport'} did rejected, please update documents, to again access to this application\'s functionality `).then(async (result: any) => {
                        if (result?.isConfirmed === true) {
                            setOpenAlert(false)
                            if (documentsRejected.emiratesReject) {
                                navigate('/agent/modal/update_emirates_front_together/not/not')
                            } else {
                                navigate('/agent/modal/update_passport_together/not/not')
                            }
                        }
                    })
                } if (expSts) {
                    void swalRejectedDoc(`Your ${documentsExpires.emiratesExpire ? 'EmiratesID' : 'Passport'} has expired, please update documents, to again access to this application\'s functionality `).then(async (result: any) => {
                        if (result?.isConfirmed === true) {
                            setOpenAlert(false)
                            if (documentsExpires.emiratesExpire) {
                                navigate('/agent/modal/update_emirates_front_together/not/not')
                            } else {
                                navigate('/agent/modal/update_passport_together/not/not')
                            }
                        }
                    })
                }
            } if (verifiedInProgress) {
                void swalInProgress(swalTexts.inProgress.message).then(async (result: any) => {
                    if (result.isConfirmed) {
                        setOpenAlert(false)
                    }
                })
            }
        } if (openAlertAddv) {

            if (!documentsLoaded.licenseLoaded) {
                void swalNeedRera(swalTexts.needRera.messageLicenseLoaded, 'Warning', typeUnit, "Attention, you do not have a RERA card").then(async (result: any) => {
                    if (result.isConfirmed) {
                        if (isAdvertising) {
                            navigate(`/agent/modalView/advertise_modal/${currentUnit.id}/${advertiseID}/not`)
                        } else {
                            handleLoad()
                        }
                        setOpenAlertAddv(false)
                    } else if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        setOpenAlertAddv(false)
                    }
                })
            } if (documentsRejected.licenseReject) {
                void swalNeedRera(swalTexts.needRera.messageLicenseReject, 'Error', typeUnit).then(async (result: any) => {
                    if (result.isConfirmed) {
                        if (isAdvertising) {
                            navigate(`/agent/modalView/advertise_modal/${currentUnit.id}/${advertiseID}/not`)
                        } else {
                            handleLoad()
                        }
                        setOpenAlertAddv(false)
                    } else if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        setOpenAlertAddv(false)
                    }
                })
            } if (documentsExpires.licenseExpire) {
                void swalNeedRera(swalTexts.needRera.messageLicenseExpire, 'Error', typeUnit).then(async (result: any) => {
                    if (result.isConfirmed) {
                        if (isAdvertising) {
                            navigate(`/agent/modalView/advertise_modal/${currentUnit.id}/${advertiseID}/not`)
                        } else {
                            handleLoad()
                        }
                        setOpenAlertAddv(false)
                    } else if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        setOpenAlertAddv(false)
                    }
                })
            } if (documentsInProgress.licenseInProgress) {
                void swalNeedRera(swalTexts.inProgress.messageForRera, 'Warning', typeUnit).then(async (result: any) => {
                    if (result.isConfirmed) {
                        if (isAdvertising) {
                            navigate(`/agent/modalView/advertise_modal/${currentUnit.id}/${advertiseID}/not`)
                        } else {
                            handleLoad()
                        }
                        setOpenAlertAddv(false)
                    } else if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        setOpenAlertAddv(false)
                    }
                })
            }
        }
    }, [openAlert, openAlertAddv])

    const [isShowFooter, setIsShowFooter] = useState(true)
    const showPopupViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.showPopupViewingReschedule)
    const blockUnitActions = areUnitActionButtonsBlocked(currentUnit) && modal[0].id === 'unit_modal'





    const removeLinksHandler = async () => {
        void swalAttentionLeave(swalConfig.attentionLeave.messageOnRemoveAdvertise).then(async (result: any) => {
            if (result?.isConfirmed) {
                const json: IResponseObjectJSON = await dispatch(removeLinksAdvertiseAction(
                    { advID: currentAdvertise.id, unitID: currentUnit?.id }
                ))
                if (json.isSuccessful) {
                    await swalSuccess(json.textNotification, () => {
                        navigate(json.textNavigate)
                    })
                } else {
                    await swalError(json.textNotification, () => {
                        navigate(json.textNavigate)
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (showPopupViewingReschedule) {
            setIsShowFooter(false)
        } else {
            setIsShowFooter(true)
        }
    }, [showPopupViewingReschedule, currentUnit])

    const previewVisible = useAppSelector((state: RootState) => state.modal.previewVisible)
    const btnClose = modal[0].footer.find((footerElement: any) => footerElement.id === 'close' || footerElement.element === 'button-secondary')
    if (location?.includes('/agent/modalView/viewing_modal/') && from === "my_unit") {
        btnClose.redirect = '/agent/schedule'
    }
    const onCheckDateRedirectToAddLinks = (): void => {
        if (currentAdvertise.attributes.StartDate) {
            navigate(`/agent/modal/agent_advertise_create_links/${currentAdvertise.attributes.Unit.data?.id}/${currentAdvertise.id}`)
        } else {
            void mainSwalDeal('When downloading the document did not set the start date of advertising, please write to BSO support, we will promptly do it.', undefined, 'Write to BSO support', 'Not now').then(async (result: any) => {
                if (result.isConfirmed) {
                    dispatch(openChatByUserID(suppChat as number))
                        .then(({ id }: { id: any }) => {
                            if (id != null) {
                                navigate(`/${userTypeLowercase}/chats/${String(id)}`)
                            }
                        })
                }
            })
        }
    }


    const onDownloadUnitDocuments = async (): Promise<void> => {
        const generateAdvertiseLinkJSON = await dispatch(generateAdvertiseLink(Number(objectID)))
        if (generateAdvertiseLinkJSON?.response?.link) {
            window.open(generateAdvertiseLinkJSON?.response?.link, '_blank')
        }
    }


    const onCheckVerifiedStatusAndGoToAction = (action: any): void => {
        if (loadSts) {
            if (verifiedStatus) {
                action()
            } else {
                setOpenAlert(true)
            }
        } else {
            onBlockLoadDocuments()
        }
    }

    const isShowBtnsForAgentsUnit = from === 'agent_s_unit' || from === 'my_unit'
    const currentUserMarketingCredits = Number((currentUser?.Settings as ISettings[])?.[0]?.MarketingCredits) ?? 0
    const priceBroadcast = 5
    isSpecialBroker = currentUnit?.attributes?.SpecialBrokers?.data?.some((SpBroker: any) => {
        return SpBroker?.id && SpBroker.id === Number(userID);
    });





    const navigateToChatMain = (collection: any, forCollection: string): any => {
        let unit = null
        if (forCollection === "forOffer") {
            unit = getBelongsUnitForOffer(collection as IOffer)
        } if (forCollection === "forViewing") {
            unit = getBelongsUnitForViewing(collection as IViewing)
        } if (forCollection === "forAdvertise") {
            unit = getBelongsUnitForViewing(collection)
        }
        const isUserTypeAgent = userType === 'Agent'

        let redirectUserID = null
        let redirectName = ""
        if (isUserTypeAgent && unit !== null) {
            if (unit.type === 'MY UNIT') {
                redirectUserID = collection?.attributes?.User?.data?.id
                redirectName = "Agent for details"
            } else if (unit.type === 'OTHER AGENT UNIT') {
                redirectUserID = collection?.attributes?.Unit?.data?.attributes?.User?.data?.id
                redirectName = "Agent for additional information"
            } else if (unit.type === 'BSO UNIT') {
                redirectUserID = suppChat
                redirectName = "BSO for additional information"
            }
        } else {
            if (unit !== null && unit.type === 'MY UNIT') {
                redirectUserID = collection?.attributes?.User?.data?.id
                redirectName = "Agent for details"
            } else {
                redirectUserID = suppChat
                redirectName = "BSO for additional information"
            }
        }
        return {
            redirectName,
            redirect: redirectUserID
        }

    }


    const onReserveAdInAwaitingList = (): void => {
        if ((!isAdvertising && isReserveOtherAgents) && isReserved && Number(userID) === Number(advertiseOwnerID)) {
            void swalJustAlertAndRedirect("You are already using awaiting list", "Reserved", "Info")
                .then(async (result: any) => {
                    if (result.isConfirmed) {
                        setOpenAlert(false)
                    }
                })
        } else {
            if ((awaitingList == null) || (awaitingList != null && awaitingList.length < 3)) {
                const advertiseReserveDates = filterVacantDateForAdvertise(matchingAdvertsFromOtherUsers)
                if (!isReserved) {
                    const nextAdvert = getNextAvailableAdvert(advertiseReserveDates, awaitingList ?? []);
                    if (nextAdvert) {
                        const daysLeft = nextAdvert.daysLeft;
                        const formattedDate = nextAdvert.EndDate !== 'TBA' ? nextAdvert.VacantDate : 'TBA';
                        swalAttentionLeave(
                            formattedDate === 'TBA' ? "Would you like to subscribe for updates and reserve it if available?" :
                                `The listings are full, but will be available on <strong> ${formattedDate} </strong> in <strong> ${daysLeft} days </strong>. Would you like to subscribe for updates and reserve it if available?`,
                            "Attention", "Reserve Advertisement").then(async (result: any) => {
                                if (result.isConfirmed) {
                                    dispatch(updateWaitingList({
                                        userID,
                                        unitID: currentUnit.id,
                                        matchingAdvertsFromOtherUsers,
                                        awaitingList
                                    }))
                                }
                            }).catch(console.log);
                    }
                }
            } else {
                void swalJustAlertAndRedirect("There is no advertising option, other agents are already using this advertising", "The listings are full", "Info")
                    .then(async (result: any) => {
                        if (result.isConfirmed) {
                            setOpenAlert(false);
                        }
                    });
            }
        }
    }


    // console.log(satedRedirect, "SATED REDIRECT IN MODAL")
    // console.log(btnClose, "btnClose")
    return (
        <>
            <div key={modalID} className="page d-flex fs-5 ">
                <div className="content w-100 mw-450px mx-auto">
                    <div className="card d-flex mh-100 ">
                        {modalID !== 'client_modal'
                            ? <ImagesPreviewGroup currentUnit={currentUnit} />
                            : <div className="card-header shadow-sm">
                                <div className="card-title">
                                    <h3>Client preview</h3>
                                </div>
                            </div>
                        }

                        <div className="card-body px-5 card-scroll h-50">
                            {
                                modalID === 'viewing_modal' || modalID === 'magic_viewing_modal'
                                    ? <ChildViewing key={`viewing-${currentViewing?.[0]?.id}`} currentViewing={currentViewing} currentUnit={currentUnit} />
                                    : modalID === 'unit_modal'
                                        ? <ChildUnit key={`unit-${currentUnit?.id}`} currentUnit={currentUnit} />
                                        : modalID === 'offer_modal' || modalID === 'magic_offer_modal'
                                            ? <ChildOffer key={`offer-${currentOffer?.id}`} offer={currentOffer} currentOffer={currentOfferRaw as IOffer} currentUnit={currentUnit} />
                                            : modalID === 'advertise_modal' || modalID === 'magic_advertise_modal'
                                                ? <ChildAdvertise key={`advertise-${currentAdvertise?.id}`} currentAdvertise={currentAdvertise} currentUnit={currentUnit} />
                                                : modalID === 'broadcast_modal'
                                                    ? <ChildBroadcast key={`broadcast-${objectID}`} broadcastID={objectID as string} currentUnit={currentUnit} />
                                                    : null
                            }

                            {
                                firstDataSource != null && firstModal != null
                                    ? <>
                                        <FormListForMyCustomForView
                                            form={firstModal[0].form}
                                            response={firstDataSource}
                                        />
                                        <div className="fv-row p-2 w-100">
                                            <div className="separator border border-bso border-3 px-2 my-4"></div>
                                        </div>
                                    </>
                                    : null
                            }

                            {modalID !== 'client_modal'
                                ? <>
                                    {modalID === "offer_modal" || modalID === "viewing_modal" || modalID === "advertise_modal" ?


                                        <div className="container  m-0 py-1 w-100 my-2 "
                                            onClick={
                                                modalID === "offer_modal" ?

                                                    () => (
                                                        dispatch(openChatByUserID(navigateToChatMain(currentOfferRaw, "forOffer").redirect)))
                                                        .then(({ id }: { id: any }) => {
                                                            if (id != null) {
                                                                navigate(`/${userTypeLowercase}/chats/${String(id)}`)
                                                            }
                                                        })
                                                    : modalID === "viewing_modal" ?
                                                        () => (
                                                            dispatch(openChatByUserID(navigateToChatMain(currentViewing[0], "forViewing").redirect)))
                                                            .then(({ id }: { id: any }) => {
                                                                if (id != null) {
                                                                    navigate(`/${userTypeLowercase}/chats/${String(id)}`)
                                                                }
                                                            })

                                                        : () => (
                                                            dispatch(openChatByUserID(navigateToChatMain(currentAdvertise, "forAdvertise").redirect)))
                                                            .then(({ id }: { id: any }) => {
                                                                if (id != null) {
                                                                    navigate(`/${userTypeLowercase}/chats/${String(id)}`)
                                                                }
                                                            })

                                            }

                                        >
                                            <div className="row mt-2">
                                                <div className="col-3 btn btn-flex btn-bso max-w-100px px-auto opacity-75">
                                                    <i className="fs-7x las la-sms"></i>
                                                </div>
                                                <div className="col-9 d-flex align-items-center bg-light-secondary">
                                                    <span className="d-flex flex-column align-items-start justify-content-center mx-2">
                                                        <span className="fs-3 fw-bold">
                                                            Chat
                                                        </span>
                                                        <span className="fs-7 lh-sm">Connect with {
                                                            modalID === "offer_modal" ? navigateToChatMain(currentOfferRaw, "forOffers").redirectName : navigateToChatMain(currentViewing[0], "forViewings").redirectName
                                                        }  <i className="las la-angle-right"></i>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div> : null
                                    }
                                    {<TakeUnitsPortfolio unitID={currentUnit.id} />}
                                    <GeoForMyCustomModalForView googleMapsLink={googleMapsLink} />
                                    <VideoForMyCustomModalForView currentUnit={currentUnit} />
                                    <FloorPlanForMyCustomModalForView currentUnit={currentUnit} />
                                    <div className="fv-row mb-2 m-0 p-2 w-100">
                                        <div className="separator border border-bso border-3 px-2 my-4"></div>
                                    </div>
                                </>
                                : <></>
                            }

                            <FormListForMyCustomForView
                                form={

                                    ['advertise_modal', 'magic_advertise_modal', 'offer_modal', 'magic_offer_modal', 'viewing_modal', 'magic_viewing_modal'].includes(modal[0].id)
                                        ? AgentModals().filter((el: any) => el.id === 'unit_modal')?.[0]?.form
                                        : modal[0].form
                                }
                                response={dataSource}
                            />
                            {modalID === 'unit_modal'
                                ? <>
                                    <div className="d-flex flex-column justify-content-end">
                                        <div className="text-gray-900 fs-6 me-0 mb-2">
                                            <CustomModalForViewRenderDescription
                                                handleClick={() => { }}
                                                marketing={currentUnit?.attributes?.Marketing ?? undefined}
                                                expanded={true}
                                            />
                                        </div>
                                    </div>
                                </>
                                : <></>
                            }
                            <div className='my-4' style={{ height: '90px' }}></div>
                        </div>
                    </div>
                </div>

                {btnClose && !loading && !previewVisible &&
                    <CloseIconBasic key={`icon-${modalID as string}`}
                        onCancel={() => {
                            modalID === 'advertise_modal' || modalID === 'viewing_modal' || modalID === 'offer_modal' || modalID === 'unit_modal' || modalID === 'broadcast_modal' ?
                                from === 'tasks' || from === 'calendar'
                                    ? navigate(`/${userTypeLowercase}/schedule`)
                                    : from === 'create'
                                        ? navigate(`/${userTypeLowercase}/units/nav`)
                                        : from === 'today'
                                            ? navigate(`/${userTypeLowercase}/dashboard`)
                                            : navigate(satedRedirect || btnClose.redirect || -1) // satedRedirect || -1)
                                : navigate(satedRedirect || btnClose.redirect || -1)  // btnClose.redirect ? btnClose.redirect : satedRedirect || -1)
                        }} />
                }






                {!previewVisible ? <div className="footer fixed-bottom bg-white shadow-sm mw-450px mx-auto"
                >

                    <div className={`d-flex flex-row w-100 h-100 p-2 ${userDevice === 'IPhone' ? 'pb-8' : 'pb-4'}  `} >
                        {loading
                            ? (
                                <button className={'btn btn-bso mt-2 mb-6 me-2 w-100  border-bso2px p-4 py-5 w-100 mx-1 lh-1 '} >
                                    <span className="indicator-progress d-block">
                                        Please wait...
                                        <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                    </span>
                                </button>
                            )
                            : null}
                        {
                            (isAgentsUnit && !(statusesViewing?.includes('Rejected'))) && (
                                <>
                                    <div
                                        className={`btn btn-bso mt-2 border-bso2px py-4 px-2 w-100 mx-1 ${userDevice === 'IPhone' ? 'mb-6' : ''}  ${blockUnitActions ? 'opacity-50' : 'opacity-100'}`}
                                        onClick={
                                            () => {
                                                navigate(`/agent/modal/agent_edit_unit/${currentUnit.id ?? unitID}/${currentUnit?.attributes?.Property?.data?.id ?? '-'}`) //  navigate('/agent/my_schedule')
                                            }}
                                    >
                                        <span className="indicator-label">
                                            Edit
                                        </span>
                                    </div >

                                    {unitStatuses.includes("Archived") ?

                                        <div
                                            className={`btn btn-bso mt-2 border-bso2px py-4 px-2 w-100 mx-1 ${userDevice === 'IPhone' ? 'mb-6' : ''}  ${blockUnitActions ? 'opacity-50' : 'opacity-100'}`}
                                            onClick={() => { dispatch(archiveAgentUnit(currentUnit.id)) }}
                                        >
                                            <span className="indicator-label">
                                                Revise unit
                                            </span>
                                        </div >
                                        : <div
                                            className={`btn btn-bso mt-2 border-bso2px py-4 px-2 w-100 mx-1 ${userDevice === 'IPhone' ? 'mb-6' : ''}  ${blockUnitActions ? 'opacity-50' : 'opacity-100'}`}
                                            onClick={
                                                priceBroadcast > currentUserMarketingCredits ?
                                                    () => {
                                                        dispatch(setShowButtonBuyCoinsOnAdvertise(true))
                                                        dispatch(setNotEnoughMarketingCredits(makePositiveOrUnchanged((priceBroadcast) - currentUserMarketingCredits)))
                                                    }
                                                    :
                                                    () => { navigate(`/${userTypeLowercase}/modal/agent_set_broadcast/${currentUnit.id ?? unitID}/-`) }
                                            }
                                        >
                                            <span className="indicator-label">
                                                Broadcast
                                            </span>
                                        </div >}

                                </>

                            )
                        }

                        {

                            (showButtonGoToActions) &&

                            (
                                <div
                                    className={`btn btn-bso mt-2 border-bso2px py-4 px-2 w-100 mx-1 ${userDevice === 'IPhone' ? 'mb-6' : ''}  ${blockUnitActions ? 'opacity-50' : 'opacity-100'}`}
                                    onClick={
                                        () => {
                                            navigate(
                                                modalID?.includes('offer_modal')
                                                    ? `/${userTypeLowercase}/modal/offer_actions_from_agent/${currentUnit.id ?? unitID}/${objectID}`
                                                    : `/${userTypeLowercase}/modal/viewing_actions_from_agent/${currentUnit.id ?? unitID}/${objectID}`)

                                        }}
                                >

                                    <span className="indicator-label">
                                        Go to Actions
                                    </span>

                                </div >
                            )
                        }

                        {isShowFooter
                            ? modal[0].footer.map((row: any) => {
                                return (
                                    < >
                                        {
                                            showAddFeedback && row.id === 'view_feedback' && !loading && !isShowBtnsForAgentsUnit &&
                                            <div className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`}
                                                onClick={
                                                    () => { onCheckVerifiedStatusAndGoToAction(() => { showFeedbackPopup(currentViewing[0], dispatch, navigate) }) }

                                                }
                                            >
                                                <span className="indicator-label">
                                                    {row.content[0]}
                                                </span>
                                            </div>
                                        }
                                        {/* cancel / revise btns */}
                                        {row.element === 'button-primary' && row.id !== 'actions' && row.id !== 'make_offer' && row.onClick != null && !loading && !isShowBtnsForAgentsUnit
                                            ? (row.id === 'cancel' || row.id === 'revise') && showButtonCancelOffer
                                                ? null
                                                : < div
                                                    className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''}  border-bso2px p-4 ${blockUnitActions ? 'opacity-50' : 'opacity-100'} py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`}
                                                    onClick={row.onClick}
                                                >
                                                    <span className="indicator-label">
                                                        {
                                                            row?.content![0]
                                                        }
                                                    </span>
                                                </div >
                                            : null
                                        }

                                        {showButtonReschedule && row.id === 'view_resch' && !loading && !isShowBtnsForAgentsUnit &&
                                            <div className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center `}
                                                onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            navigate(`/agent/modal/agent_update_viewing_modal_close_btn/${unitID}/${objectID}`)
                                                        })
                                                    }
                                                }
                                            >
                                                {loading
                                                    ? <span className="indicator-progress d-block">
                                                        Please wait...
                                                        <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                    </span>
                                                    : <span className="indicator-label">
                                                        {row.content[0]}
                                                    </span>
                                                }

                                            </div>
                                        }

                                        {showButtonApproveViewing && row.id === 'view_approve' && !loading && !isShowBtnsForAgentsUnit &&
                                            <div className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`}
                                                onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            void approveHandler()
                                                        })
                                                    }
                                                }
                                            >
                                                {loading
                                                    ? <span className="indicator-progress d-block">
                                                        Please wait...
                                                        <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                    </span>
                                                    : <span className="indicator-label">
                                                        {row.content[0]}
                                                    </span>
                                                }
                                            </div>
                                        }


                                        {showButtonNegotiateOffer && row.id === 'offer_neg' && !loading && !isShowBtnsForAgentsUnit &&

                                            <div className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`}
                                                onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            navigate(`/agent/modal/agent_to_agent_negotiate_offer/${unitID}/${currentOffer.id}`)
                                                        })
                                                    }
                                                }
                                            >
                                                {loading
                                                    ? <span className="indicator-progress d-block">
                                                        Please wait...
                                                        <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                    </span>
                                                    : <span className="indicator-label">
                                                        {row.content[0]}
                                                    </span>
                                                }
                                            </div >
                                        }
                                        {showButtonNegotiateOffer && row.id === 'offer_neg_approve' && !loading && !isShowBtnsForAgentsUnit &&

                                            <div className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`}
                                                onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            swalAttentionLeave('Are you sure?')
                                                                .then(async (result: any) => {
                                                                    if (result.isConfirmed) {
                                                                        const request = await dispatch(
                                                                            updateStatusOfferAgent({
                                                                                offerID: currentOffer.id,
                                                                                status: 'Confirmed',
                                                                                notify: 'Other Agent'
                                                                            })
                                                                        )

                                                                        if (request.success && request.response.data) {
                                                                            const inMomentTime = toUTCWithOffset(getCurrentDubaiDate())
                                                                            const typeOffer = currentOfferRaw?.attributes?.Type?.[0]?.__component?.split('.')[1] ?? ''
                                                                            await sendNotification(inMomentTime, Number(currentOffer.AgentToAgentID), request?.response?.data?.id, 'admin-offer-confirmed', `Attention kindy, ${nameUser} approved your offer on ${getUnitAddress(currentUnit)} , please respond.`)
                                                                            await Swal.fire({
                                                                                ...optionsSuccess,
                                                                                title: 'Confirmed!',
                                                                                html: 'You have successfully confirmed offer'
                                                                            }).then(async (value: any) => {
                                                                                if (value.isConfirmed) {
                                                                                    navigate(`/agent/modalView/offer_modal/${currentUnit.id}/${currentOffer.id}/${from}?type=${typeOffer ?? 'not'}`)
                                                                                }
                                                                            })
                                                                        } else {
                                                                            await Swal.fire({
                                                                                ...swalOptions('error'),
                                                                                ...{
                                                                                    title: 'Something wrong',
                                                                                    html: 'Try again'
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                                .catch(console.log)
                                                        })
                                                    }
                                                }
                                            >
                                                {loading
                                                    ? <span className="indicator-progress d-block">
                                                        Please wait...
                                                        <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                    </span>
                                                    : <span className="indicator-label">
                                                        {row.content[0]}
                                                    </span>
                                                }
                                            </div >
                                        }

                                        {showButtonCancel && row.id === 'view_cancel' && !loading && !isShowBtnsForAgentsUnit &&
                                            <div className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`}
                                                onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            void onCancel()
                                                        })
                                                    }
                                                }
                                            >
                                                {loading
                                                    ? <span className="indicator-progress d-block">
                                                        Please wait...
                                                        <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                    </span>
                                                    : <span className="indicator-label">
                                                        {row.content[0]}
                                                    </span>
                                                }
                                            </div >
                                        }
                                        {
                                            row.id === 'view_scan_qr' && showButtonScanQR && !loading && !isShowBtnsForAgentsUnit && (
                                                <button className={`btn btn-bso mt-2  ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`} onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            navigate(`/${userTypeLowercase}/modal/scan_qrcode/${currentUnit?.id}/${currentViewing[0].id}`)
                                                        })
                                                    }
                                                } >
                                                    {loading
                                                        ? <span className="indicator-progress d-block">
                                                            Please wait...
                                                            <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                        </span>
                                                        : <span className="indicator-label">
                                                            {row.content[0]}
                                                        </span>
                                                    }

                                                </button >
                                            )
                                        }


                                        {
                                            row.id === 'delete_add_links' && currentAdvertise?.attributes?.Statuses?.find(el => el === 'Advertisement closed' || el === 'Ads removing rejected') && !isShowBtnsForAgentsUnit && !loading && (
                                                <button className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`} onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            void removeLinksHandler()
                                                        })
                                                    }
                                                } >
                                                    <span className="indicator-label">
                                                        {row.content[0]}
                                                    </span>
                                                </button>
                                            )
                                        }

                                        {
                                            row.id === 'add_links' && currentAdvertise?.attributes?.Statuses?.find(el => ((el === 'Docs received' || el === 'Links added') && typeUnit === 'For rent') || (typeUnit === 'For sale' && el === 'Confirmed')) && !loading && !isShowBtnsForAgentsUnit && (
                                                <button className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''}   border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`} onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            onCheckDateRedirectToAddLinks()
                                                        })
                                                    }
                                                } >
                                                    <span className="indicator-label">
                                                        {row.content[0]}
                                                    </span>
                                                </button>
                                            )
                                        }

                                        {
                                            row.id === 'send_a_form' && currentAdvertise?.attributes?.Statuses?.find(el => typeUnit === 'For sale' && (el === 'Docs received' || el === 'A Form rejected')) && !loading && !isShowBtnsForAgentsUnit && (
                                                <button className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''}   border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`} onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            navigate(`/agent/modal/agent_advertise_send_form/${unitID}/${objectID}`)
                                                        })
                                                    }

                                                } >
                                                    <span className="indicator-label">
                                                        {row.content[0]}
                                                    </span>
                                                </button >
                                            )
                                        }

                                        {
                                            row.id === 'download_docs' && currentAdvertise?.attributes?.Statuses?.find(el => el === 'In-process' || el === 'Docs received' || el === 'Links added' || el === 'Links rejected' || el === 'A Form added' || el === 'A Form rejected' || el === 'Confirmed') && !loading && !isShowBtnsForAgentsUnit && (
                                                <button className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''}   border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`} onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            void onDownloadUnitDocuments()
                                                        })
                                                    }

                                                } >

                                                    <span className="indicator-label">
                                                        {row.content[0]}
                                                    </span>
                                                </button >
                                            )
                                        }

                                        {
                                            row.id === 'edit_links' && currentAdvertise?.attributes?.Statuses?.find(el => el === 'Links rejected') && !loading && !isShowBtnsForAgentsUnit && (
                                                <button className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`} onClick={
                                                    () => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            navigate(`/agent/modal/agent_advertise_create_links/${currentAdvertise.attributes.Unit.data?.id}/${currentAdvertise.id}`)
                                                        })
                                                    }
                                                } >
                                                    <span className="indicator-label">
                                                        {row.content[0]}
                                                    </span>
                                                </button >
                                            )
                                        }

                                        {
                                            row.element === 'button-dark' && row.id === 'advertise' && !loading && !isOtherAgentUnit && !isShowBtnsForAgentsUnit && (
                                                <div
                                                    className={`btn btn-bso mt-2  ${userDevice === 'IPhone' ? 'mb-6' : ''}  ${blockUnitActions ? 'opacity-50' : 'opacity-100'}  border-bso2px  p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center ${isShowBtnsForAgentsUnit && 'lh-sm'}`}
                                                    onClick={
                                                        () => {
                                                            onCheckVerifiedStatusAndGoToAction(() => {
                                                                if (isAdvertising) {
                                                                    dispatch(setWhereAfterToGo('/agent/units/nav'))
                                                                    navigate(`/agent/modalView/advertise_modal/${currentUnit.id}/${advertiseID}/not`);
                                                                } else if (loadSts && verifiedStatus && !blockUnitActions && statusesApproved.licenseApproved) {
                                                                    if (isSpecialBroker) {
                                                                        dispatch(userUpdateClickCount('advertise'));
                                                                        dispatch(setWhereAfterToGo('/agent/units/nav'))
                                                                        navigate(`/agent/modal/agent_advertise_create_duration/${currentUnit.id}/${userID}`)
                                                                    } else {
                                                                        dispatch(userUpdateClickCount('advertise'));
                                                                        if (currentUnit?.attributes?.ImagesOnly === true) {
                                                                            void swalJustAlertAndRedirect("There is no advertising option, other agents are already using this advertising.You can just share unit's details", "The listings are full", "Info")
                                                                                .then(async (result: any) => {
                                                                                    if (result.isConfirmed) {
                                                                                        setOpenAlert(false);
                                                                                    }
                                                                                });
                                                                        } else {
                                                                            if (isAdvertiseArea) {
                                                                                if (!isAdvertising && isReserveOtherAgents) {
                                                                                    onReserveAdInAwaitingList()
                                                                                } else {
                                                                                    dispatch(setWhereAfterToGo('/agent/units/nav'))
                                                                                    navigate(isAdvertising
                                                                                        ? `/agent/modalView/advertise_modal/${currentUnit.id}/${advertiseID}/not`
                                                                                        : `/agent/modal/agent_advertise_create_duration/${currentUnit.id}/${userID}`);
                                                                                }
                                                                            } else {
                                                                                onBlockAdvertise();
                                                                            }
                                                                        }

                                                                    }

                                                                } else if (!statusesApproved.licenseApproved) {
                                                                    dispatch(userUpdateClickCount('advertise'));
                                                                    setOpenAlertAddv(true);
                                                                }
                                                            })
                                                        }
                                                    }

                                                >

                                                    {loading
                                                        ? <span className="indicator-progress d-block">
                                                            Please wait...
                                                            <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                        </span>
                                                        : <span className="indicator-label">
                                                            {isAdvertising ? 'Advertised' :
                                                                (!isAdvertising && isReserveOtherAgents) && isReserved ?
                                                                    Number(userID) === Number(advertiseOwnerID) ?
                                                                        'Reserved Ad' :
                                                                        'Advertise'
                                                                    : 'Advertise'}
                                                            {!isAdvertising && isReserveOtherAgents && !isReserved && !isSpecialBroker
                                                                ? <Icon name={'lock'} className={'fs-2x text-white  '} />
                                                                : null}
                                                        </span>

                                                    }
                                                </div >
                                            )
                                        }

                                        {
                                            row.element === 'button-white' && row.id === 'book_viewing' && !isShowBtnsForAgentsUnit && (
                                                <div
                                                    onClick={() => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            if (isOtherAgentUnit) {
                                                                dispatch(userUpdateClickCount('viewing-agent'))
                                                                navigate(`/agent/modal/agent_set_viewing_modal/${currentUnit.id}/${userID}`)
                                                            } else {
                                                                if (!blockUnitActions) {
                                                                    openAddViewingModal(currentUnit, navigate, dispatch)
                                                                }
                                                            }
                                                        })
                                                    }}

                                                    className={` btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px ${blockUnitActions ? 'opacity-50' : 'opacity-100'} p-4 py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`}
                                                >
                                                    {loading
                                                        ? <span className="indicator-progress d-block">
                                                            Please wait...
                                                            <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                        </span>
                                                        : <span className="indicator-label">
                                                            {row.content[0]}
                                                        </span>
                                                    }
                                                </div >

                                            )
                                        }

                                        {/* {
                                            row.element === 'button-white' && row.id === 'book_viewing' && !isShowBtnsForAgentsUnit && (from === 'show_completed') && (
                                                <div
                                                    onClick={() => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            onCompletedOffer()
                                                        })
                                                    }}
                                                    className={` btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px ${blockUnitActions ? 'opacity-50' : 'opacity-100'} py-5 w-100  lh-1 d-flex align-items-center justify-content-center`}
                                                >
                                                    {loading
                                                        ? <span className="indicator-progress d-block">
                                                            Please wait...
                                                            <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                        </span>
                                                        : <span className="indicator-label">
                                                            Completed offer
                                                        </span>
                                                    }
                                                </div >
                                            )
                                        } */}

                                        {row.element === 'button-primary' && row.id !== 'actions' && (!isShowBtnsForAgentsUnit || isOtherAgentUnit) && (
                                            row.id === 'make_offer' && showButtonMakeOffer
                                                ? <div className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''}  border-bso2px p-4 ${blockUnitActions ? 'opacity-50' : 'opacity-100'} py-5 w-100 mx-1 lh-1 d-flex align-items-center justify-content-center`}
                                                    onClick={() => {
                                                        onCheckVerifiedStatusAndGoToAction(() => {
                                                            if (isOtherAgentUnit) {
                                                                dispatch(userUpdateClickCount('offer-agent'))
                                                                navigate(`/agent/modal/agent_to_agent_make_offer/${unitID}/${userID}`)
                                                            } else {
                                                                if (unitID != null && !blockUnitActions) {
                                                                    dispatch(userUpdateClickCount('offer'))
                                                                    void dispatch(scanDocumentsForOffer(+unitID, getOfferType(currentUnit), navigate, false))
                                                                }
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <span className="indicator-label">{row?.content![0]}</span>
                                                </div >
                                                : null
                                        )}

                                        {
                                            row.element === 'button-primary' && row.id === 'actions' && !isShowBtnsForAgentsUnit && (
                                                <Link
                                                    to={`${`/landlord/${actionRoutes[modal[0].id] ?? ''}/${unitID ?? '-'}/${objectID ?? '-'}`}`}
                                                    className={`btn btn-bso mt-2 border-bso2px py-4 px-2 w-100 mx-1 ${userDevice === 'IPhone' ? 'mb-6' : ''}  `}
                                                >
                                                    <div>
                                                        <span className="indicator-label">{row?.content![0]} </span>
                                                    </div>
                                                </Link>
                                            )
                                        }
                                        {
                                            row.id === 'offer_action' && offerReactRequireStatuses.includes(currentOffer.Statuses[0]) && !isShowBtnsForAgentsUnit && !currentUnit?.attributes?.Statuses.includes('Door close') &&
                                            <button className={`btn btn-bso mt-2 border-bso2px py-4 px-2  w-100 mx-1 ${userDevice === 'IPhone' ? 'mb-6' : ''}`}
                                                onClick={() => {
                                                    onCheckVerifiedStatusAndGoToAction(() => {
                                                        void processOfferSwal(currentOfferRaw as IOffer, dispatch, navigate)
                                                    })
                                                }}
                                            >
                                                <span className="indicator-label">
                                                    Take action
                                                </span>
                                            </button>
                                        }
                                    </>
                                )
                            }

                            )
                            : null}
                    </div >
                </div > : null}
            </div >


            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '88vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={() => {
                    dispatch(setButtonDriverExtendLocation(false))
                    window.localStorage.removeItem(USER_AREAS_EXTEND_COUNT)
                    window.localStorage.removeItem(USER_CHOOSE_EXTEND_AREA)
                    dispatch(setIsExtend(false))
                    dispatch(setUnitIDnotEnoughAreaForAdvertise(undefined))
                    dispatch(setWhatIsNotThereArea(null))
                    dispatch(setButtonDriverExtendLocation(false))
                }}
                open={buttonDriverExtendLocation}
                rootStyle={{ zIndex: '1100' }}
                key={'parent-driver-areas'}
                maskClosable={true}
            >
                <div key={'child-driver-areas'}
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div className="w-100 mw-450px  mx-auto">
                        <AddOnsServiceArea />
                    </div>
                </div>
            </Drawer>
            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '88vh', // maxHeight: '80vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={() => { dispatch(setButtonDriverAddOnsParent(false)); dispatch(setNotEnoughMarketingCredits(0)) }}
                open={buttonDriverAddOnsParent}
                rootStyle={{ zIndex: '1100' }}
                key={'parent-driver-bricks'}
                maskClosable={true}
            >
                <div key={'child-driver-bricks'} className="content d-flex flex-column flex-fill p-0 m-1 px-2">
                    <div className="w-100 mw-450px  mx-auto">
                        <AddOnsServiceBricks />
                    </div>
                </div>
            </Drawer>


            {/* {
                showPopupAddOnsAreas
                    ? <HideButtonListAddOns title='ChooseAreasListHide'>
                        < ChooseAreasList key={`ChooseAreasListHide-list`
                        } alone={true} />
                    </HideButtonListAddOns >
                    : null} */}
        </>
    )
}
