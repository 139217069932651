import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { AgentModals } from '../../functions/agent/AgentModals'
import { LandlordModals } from '../../functions/landlord/data/LandlordModals'

import CustomFieldsHook from './CustomFieldsHook'
import CustomValidatorHook from './CustomValidatorHook'

import { setDop, setPlan, setService } from '../../store/slices/serviceSlice'
import { getServices } from '../../functions/landlord/data/servicesSubm'
import useEditUnit from '../../hooks/formModals/useEditUnit'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { addProperty, editProperty } from '../../store/actionsCreators/propertyActions'
import {
    applyUnitModalFilters,
    setUnitsFilters, setUnitSold,
    updateUnit
} from '../../store/actionsCreators/unitsActions'

import useEditFilters from '../../hooks/formModals/useEditFilters'
import TutorialLoadDocuments from '../../components/agentModule/tutorials/TutorialLoadDocuments'
import { addAgentSettings, addClient } from '../../store/actionsCreators/userActions'

import { addOffer, cancelOffer, saveAgentOfferNegotiate } from '../../store/actionsCreators/offerActions'

import fillAddOfferModal from '../../functions/agent/modals/filAddOfferModal'
import { resetPasswordCheckMailOTP, resetPasswordCheckMobileOTP, resetPasswordSendMailOTP, resetPasswordSendMobileOTP, resetPasswordWithEmail, resetPasswordWithPhone } from '../../store/actionsCreators/userActions/resetPasswordActions'
import { changeNumber, changeNumberChangePhoneConfirm, changeNumberVerifyPhoneConfirm } from '../../store/actionsCreators/userActions/changePhoneActions'
import { changeEmail, changeEmailVerifyPhoneConfirm } from '../../store/actionsCreators/userActions/changeEmailActions'
import { type AppDispatch, type RootState, store } from '../../store/store'
import useUserType from '../../hooks/UseUserType'

import {
    dropModalSlice,
    setDateChooseViewing,
    setImages, setRescheduleReason,
    setShowButtonBuyCoinsOnAdvertise
} from '../../store/slices/modalSlice'
import fillEditPropertyModal from '../../functions/agent/modals/fillEditPropertyModal'
import { updateAvatar } from '../../store/actionsCreators/userActions/avatarActions'
import { createAdvertise } from '../../store/actionsCreators/advertiseActions/createAdvertiseAction'
import { scanQrCodeForPickUpKeys } from '../../store/actionsCreators/viewingActions/scanQrCodeForPickUpKeys'
import { addCardAction } from '../../store/actionsCreators/userActions/paymendActions/addCardAction'
import { uploadFile } from '../../store/actionsCreators/documentsActions/upload/uploadFile'
import {
    createKeyBookingByMagic,
    updateViewingByMagic
} from '../../store/actionsCreators/viewingActions/magicViewingActions'
import { updateOfferByMagic, uploadOfferDocument } from '../../store/actionsCreators/offerActions/magicOfferActions'
import { swalAttentionLeave, swalSuccess } from '../../functions/swalOptions'
import magicFillCheckLinks from '../../functions/shared/modals/magicFillCheckLinks'
import { setAdvertiseLinksStatus } from '../../store/actionsCreators/advertiseActions/setAdvertiseLinksStatus'
import { setModalDateChooseViewing } from '../../store/actionsCreators/viewingActions/setModalDateChooseViewing'
import { addOfferTenantType } from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferTenantType'
import { addOfferIndividualDocument } from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferIndividual/addOfferIndividualDocument'
import {
    addOfferIndividualClientInfo
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferIndividual/addOfferIndividualClientInfo'
import {
    addOfferIndividualOfferDetails
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferIndividual/addOfferIndividualOfferDetails'
import {
    addOfferCompanyDocument
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferCompany/addOfferCompanyDocument'
import {
    addOfferCompanyClientInfo
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferCompany/addOfferCompanyClientInfo'
import {
    addOfferCompanyOfferDetails
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferCompany/addOfferCompanyOfferDetails'
import { addOfferSaleTenantType } from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferTenantType'
import {
    addOfferSaleIndividualDocument
} from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferIndividual/addOfferIndividualDocument'
import {
    addOfferSaleIndividualOfferDetails
} from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferIndividual/addOfferIndividualOfferDetails'
import { magicCheckBookingForm } from '../../store/actionsCreators/offerActions/magicCheckBookingForm'
import {
    addOfferCompanySaleDocument
} from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferCompany/addOfferCompanyDocument'
import {
    addOfferCompanySaleOfferDetails
} from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferCompany/addOfferCompanyOfferDetails'
import swalConfig from '../texts/swalTexts'
import { setDebitMarketingCredits, setNotEnoughMarketingCredits } from '../../store/slices/advertisesSlice'
import fillFaqModal from '../../functions/shared/modals/fillFaqModal'
import CustomModalHeader from './CustomModalHeader'
import { USER_ADVERTISE_DAYS } from '../../urls'
import { createStartDateAdvertise } from '../../store/actionsCreators/advertiseActions/createStartDateAdvertise'
import { sendAdvertiseForm } from '../../store/actionsCreators/advertiseActions/sendAdvertiseForm'
import { extendAdvertise } from '../../store/actionsCreators/advertiseActions/extendAdvertise'
import fillAdminOfferDocumentModal from '../../functions/shared/modals/fillAdminOfferDocumentModal'
import { checkAFormSwitchAction } from '../../store/actionsCreators/advertiseActions/checkAFormSwitchAction'
import { checkAForm } from '../../store/actionsCreators/advertiseActions/checkAForm'
import dayjs from 'dayjs'
import { createPropertyForUnit } from '../../store/actionsCreators/propertyActions/createPropertyAction'
import { createAdditionalOptionsForUnit } from '../../store/actionsCreators/unitActions/createAdditionalOptionsForUnit'
import { createMarketingForUnit } from '../../store/actionsCreators/unitActions/createMarketingForUnit'
import { editAgentUnit } from '../../store/actionsCreators/unitActions/editAgentUnit'
import { setClientContactInfo } from '../../store/actionsCreators/offerActions/setContactInfo/setClientContactInfo'
import {
    fillSetClientContactInfoModal
} from '../../store/actionsCreators/offerActions/setContactInfo/fillSetClientContactInfoModal'
import { createBroadcast } from '../../store/actionsCreators/broadcastActions/createBroadcast'
import fillDepositSlipOfferModal from '../../functions/agent/modals/fillDepositSlipOfferModal'
import { addOfferPaymentConfirmation } from '../../store/actionsCreators/offerActions/addPaymentConfirmation/addOfferPaymentConfirmation'
import { makeOfferAgentToAgent } from '../../store/actionsCreators/offerActions/agentToAgentOfferCommunication/makeOfferAgentToAgent'
import { makeOfferAgentToAgentLastPart } from '../../store/actionsCreators/offerActions/agentToAgentOfferCommunication/makeOfferAgentToAgentLastPart'
import { negotiateOfferAgentToAgent } from '../../store/actionsCreators/offerActions/agentToAgentOfferCommunication/negotiateOfferAgentToAgent'
import {
    makeOfferAgentToBSOClientInfo
} from '../../store/actionsCreators/offerActions/agentToBSOOfferCommunication/makeOfferAgentToBSOClientInfo'
import nanToNull from '../../functions/adapters/universal/nanToNull'
import {
    makeOfferAgentToBSOOfferInfo
} from '../../store/actionsCreators/offerActions/agentToBSOOfferCommunication/makeOfferAgentToBSOOfferInfo'
import { createPeriodBisyKeys } from '../../store/actionsCreators/viewingActions/createPeriodBisyKeys'
import { createViewing } from '../../store/actionsCreators/viewingActions/createViewing'
import { updateViewing } from '../../store/actionsCreators/viewingActions/updateViewing'
import { updateViewingByOtherAgent } from '../../store/actionsCreators/viewingActions/updateViewingByOtherAgent'
import { createBroadcastNew } from '../../store/actionsCreators/broadcastActions/createBroadcastNew'
import { updateAreasForBricksPay } from '../../store/actionsCreators/userActions/updateAreasForBricksPay'
import {
    makeOfferAgentToBSOAdditionalInfo
} from "../../store/actionsCreators/offerActions/agentToBSOOfferCommunication/makeOfferAgentToBSOAdditionalInfo";
import { updateProfile } from '../../store/actionsCreators/userActions/updateProfile'

interface CustomModalProps {
    modalID?: string
    unitID?: string
    objectID?: string
    close?: () => void
}

export default function CustomModal(props: CustomModalProps): JSX.Element {
    let { modalID, unitID, objectID } = useParams()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const dispatch = useAppDispatch()
    if (_.some([props.modalID, props.unitID, props.objectID], prop => prop !== undefined)) {
        modalID = props.modalID
        unitID = props.unitID
        objectID = props.objectID
    }

    const units = useAppSelector((state) => state.units.units)
    const properties = useAppSelector((state) => state.property.properties)
    const newProperty = useAppSelector((state) => state.property.newPropertyFields)
    const temporaryTitleDeed = useAppSelector((state) => state.documents.temporaryTitleDeed)

    // const [defaultFields, setDefaultFields] = useState(initializeFields())
    // const [modals, setModals] = useState<any[]>([...LandlordModals, ...AgentModals(defaultFields)])

    const unitFields = units.filter((unit) => unit.id === Number(unitID)) ?? [] as any
    const propertyFields = {
        ...unitFields?.[0]?.attributes?.Property?.data?.attributes
    }

    const [modals, setModals] = useState<any[]>([...LandlordModals, ...AgentModals({
        unitFields,
        propertyFields,
        newProperty
    })])

    useEffect(() => {
        const unitsFields = store.getState().units.units.filter((unit) => unit.id === Number(unitID)) ?? []
        const newProperty = store.getState().property.newPropertyFields
        const propertyFields = {
            ...unitsFields?.[0]?.attributes?.Property?.data?.attributes,
            Name: newProperty?.name,
            // Type: newProperty?.type,
            Area: newProperty?.area,
            MAKANI: newProperty?.makani
        }

        const updatedUnitFields = unitsFields.length > 0
            ? [{
                ...unitsFields[0],
                attributes: {
                    ...unitsFields[0].attributes,
                    Number: (newProperty?.number != null) ? Number(newProperty?.number) : unitsFields[0].attributes.Number ?? null
                }
            }]
            : []

        const newModals = [...LandlordModals, ...AgentModals({
            unitFields: updatedUnitFields,
            newProperty,
            propertyFields
        })]
        setModals(newModals)
    }, [temporaryTitleDeed, newProperty, modalID, properties]) // ?.name, newProperty?.number, newProperty?.area
    let modal = modals.filter((modal: any) => modal.id === modalID)

    if (props.close !== undefined) {
        const closeButton = modal[0]?.footer?.find((button: any) => button.id === 'close') ?? {}
        closeButton.close = props.close
    }

    const key = modalID
    let callback
    let handleReset

    const navigate = useNavigate()
    const { userType } = useUserType()

    const state: RootState = store.getState()// useAppSelector((state: RootState) => state)
    const modalInitialData = useAppSelector((state: RootState) => state.modal.initialData)

    let initialData = JSON.parse(JSON.stringify(modalInitialData))
    useEffect(() => {
        const pageServices = getServices(modalID ?? '')
        dispatch(setService(pageServices))
        const initialPlan = ((pageServices?.plans?.[0]) != null) || {}
        dispatch(setPlan(initialPlan))
        return () => {
            dispatch(setService(undefined))
            dispatch(setPlan(undefined))
            dispatch(setDop([]))
            dispatch(setImages([]))
            if (key === 'agent_set_offer_modal_residential') {
                dispatch(dropModalSlice())
            }
        }
    }, [])

    switch (key) {
        case 'add_card_modal':
            callback = addCardAction
            break
        case 'scan_qrcode':
            callback = scanQrCodeForPickUpKeys
            break
        case 'agent_advertise_create_duration':
            callback = createAdvertise
            break
        // case 'agent_advertise_extend_duration':
        //     callback = extendAdvertise
        //     break

        case 'agent_advertise_send_form':
            callback = sendAdvertiseForm
            break

        case 'agent_advertise_create_start_date':
            callback = createStartDateAdvertise
            break

        case 'agent_advertise_create_links':
            callback = null
            break
        case 'set_time_for_take_keys_modal':
            callback = createPeriodBisyKeys
            break
        case 'agent_avatar':
            callback = updateAvatar
            break
        case 'my_profile_reset_password_way_email':
            callback = resetPasswordSendMailOTP
            break
        case 'my_profile_reset_password_way_phone':
            callback = resetPasswordSendMobileOTP
            break
        case 'my_profile_password_verify_phone':
            callback = resetPasswordCheckMobileOTP
            break
        case 'my_profile_password_verify_email':
            callback = resetPasswordCheckMailOTP
            break
        case 'my_profile_reset_password_final_phone':
            callback = resetPasswordWithPhone
            break
        case 'my_profile_reset_password_final_email':
            callback = resetPasswordWithEmail
            break
        case 'change_phone_for_contacts_my_phone_verify':
            callback = changeNumberChangePhoneConfirm
            break
        case 'change_phone_for_contacts_my_check_otp':
            callback = changeNumberVerifyPhoneConfirm
            break
        case 'change_phone_for_contacts_my':
            callback = changeNumber
            break
        case 'change_email_for_contacts_my_check_otp':
            callback = changeEmailVerifyPhoneConfirm
            break
        case 'change_email_for_contacts_my':
            callback = changeEmail
            break
        case 'update_emirates_back_together':
            callback = uploadFile
            break
        case 'update_emirates_front_together':
            callback = uploadFile
            break
        case 'update_passport_together':
            callback = uploadFile
            break
        case 'update_passport':
            callback = uploadFile
            break
        case 'update_emirates_front':
            callback = uploadFile
            break
        case 'update_emirates_back':
            callback = uploadFile
            break
        case 'update_rera':
            callback = uploadFile
            break
        case 'update_rera_deal':
            callback = uploadFile
            break
        case 'agent_settings':
            callback = addAgentSettings
            break
        case 'agent_settings_for_subscriptions':
            callback = addAgentSettings
            break
        case 'agent_settings_for_areas_extension':
            // if (unitID === 'addons') {
            callback = updateAreasForBricksPay
            // } else {
            //     callback = addAgentSettings
            // }

            break
        case 'agent_set_viewing_modal':
            callback = createViewing
            break
        case 'agent_update_viewing_modal':
            callback = updateViewing
            break
        case 'agent_update_viewing_modal_close_btn':
            callback = updateViewing
            break

        case 'set_property_modal':
            callback = addProperty
            break
        case 'edit_property_modal':
            callback = editProperty
            initialData = fillEditPropertyModal(state.property.properties, _.find(state.setUnit.staticForm, (row) => row.id === 'Property')?.value ?? -1)
            break
        case 'set_unit_marketing_modal': //
            initialData = useEditUnit(modal, +(props.unitID ?? -1), true)
            modal = initialData.modal
            callback = (data: any) => async (dispatch: AppDispatch, getState: (state: RootState) => RootState) => {
                const response = await dispatch(updateUnit(data, +(unitID ?? props.unitID ?? -1), true))
                if (response?.isSuccessful === true && response?.response?.error == null) {
                    response.textNavigate = '/landlord/my_units'
                    response.textNotification = ''
                } else {
                    response.textNavigate = ''
                }
                return response
            }
            break
        case 'edit_unit_marketing_modal': //
            initialData = useEditUnit(modal, +(props.unitID ?? -1))
            modal = initialData.modal
            callback = (data: any) => updateUnit(data, +(unitID ?? props.unitID ?? -1), true)
            break
        case 'agent_unit_filters_modal':
            initialData = useEditFilters(modal)
            modal = initialData.modal
            callback = applyUnitModalFilters
            handleReset = () => {
                dispatch(setUnitsFilters({}))
                navigate(-1)
            }
            break
        case 'agent_set_offer_modal_residential': //
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = (data: any) => addOffer(data, 'residential', +(unitID ?? -1), objectID !== '-' && objectID != null ? +objectID : undefined)
            break
        case 'agent_set_offer_modal_commercial': //
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = (data: any) => addOffer(data, 'commercial', +(unitID ?? -1), objectID !== '-' && objectID != null ? +objectID : undefined)
            break
        case 'agent_set_offer_modal_sale': //
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = (data: any) => addOffer(data, 'sale', +(unitID ?? -1), objectID !== '-' && objectID != null ? +objectID : undefined)
            break
        case 'agent_edit_offer_modal_residential':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState)
            modal = initialData.modal
            callback = (data: any) => addOffer(data, 'residential', +(unitID ?? -1), objectID !== '-' && objectID != null ? +objectID : undefined, true)
            break
        case 'agent_set_offer_modal_tenant_type':
            callback = addOfferTenantType(unitID, objectID)
            break
        case 'agent_set_offer_modal_documents_individual':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferIndividualDocument(unitID, objectID)
            break
        case 'agent_set_offer_modal_client_info_individual':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferIndividualClientInfo(unitID, objectID)
            break
        case 'agent_set_offer_modal_offer_details_individual':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferIndividualOfferDetails(unitID, objectID)
            break
        case 'agent_set_offer_modal_documents_company':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferCompanyDocument(unitID, objectID)
            break
        case 'agent_set_offer_modal_client_info_company':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferCompanyClientInfo(unitID, objectID)
            break
        case 'agent_set_offer_modal_offer_details_company':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferCompanyOfferDetails(unitID, objectID)
            break
        case 'agent_set_offer_modal_tenant_type_sale':
            callback = addOfferSaleTenantType(unitID, objectID)
            break
        case 'agent_set_offer_modal_documents_individual_sale':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferSaleIndividualDocument(unitID, objectID)
            break
        case 'agent_set_offer_modal_offer_details_individual_sale':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferSaleIndividualOfferDetails(unitID, objectID)
            break
        case 'agent_set_offer_modal_documents_company_sale':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferCompanySaleDocument(unitID, objectID)
            break
        case 'agent_set_offer_modal_offer_details_company_sale':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferCompanySaleOfferDetails(unitID, objectID)
            break
        case 'offer_modal': //
            // modal = fillOfferModal(modal, +(objectID ?? '-1'), searchParams, state.user.currentUser)
            handleReset = () => {
                dispatch(cancelOffer(+(objectID ?? '-1')))
                    .finally(() => {
                        navigate(-1)
                    })
            }
            break
        case 'checkout_modal':
            callback = (data: any) => (dispatch: AppDispatch, getState: (state: RootState) => RootState) => {
                // alert(1)
                navigate(-1)
            }
            break
        case 'agent_add_client_modal':
            callback = addClient
            break
        case 'reject_viewing_modal':
            callback = (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Rejected'] }, 'The viewing is successfully rejected', 'user-viewing-rejected', 'ATTENTION viewing is rejected')
            break
        case 'reschedule_viewing_modal':
            callback = (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Rescheduled by BSO'], Datetime: new Date(`${data.Datetime as string}+04:00`) }, 'The viewing is successfully rescheduled', 'user-viewing-newtime', 'ATTENTION viewing is rescheduled')
            break
        case 'reschedule_viewing_modal_lock':
            callback = (data: Record<string, unknown>) => (dispatch: AppDispatch, getState: () => RootState) => {
                // dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`)))
                dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`).toISOString()))
                dispatch(setRescheduleReason(data.RescheduleReason))
                return {
                    isSuccessful: true,
                    textNotification: '',
                    textNavigate: `/landlord/modal/lock_pass_viewing_modal_reschedule/${String(unitID)}/${String(objectID)}`
                }
            }
            // (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Rescheduled by BSO'], Datetime: new Date(`${data.Datetime as string}+04:00`) }, 'The viewing is successfully rescheduled', 'user-viewing-newtime', 'ATTENTION viewing is rescheduled', `/landlord/modal/lock_pass_viewing_modal_reschedule/${String(unitID)}/${String(objectID)}`)
            // callback = (data: Record<string, unknown>) => {
            //     // dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`)))
            //     dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`).toISOString()))
            //     return {
            //         isSuccessful: true,
            //         textNotification: '',
            //         textNavigate: `/landlord/modal/lock_pass_viewing_modal_reschedule/${String(unitID)}/${String(objectID)}`
            //     }
            // }
            break
        case 'reschedule_viewing_modal_keys':
            callback = (data: Record<string, unknown>) => (dispatch: AppDispatch, getState: () => RootState) => {
                //  dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`)))
                dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`).toISOString()))
                dispatch(setRescheduleReason(data.RescheduleReason))
                return {
                    isSuccessful: true,
                    textNotification: '',
                    textNavigate: `/landlord/modal/set_key_bookings_modal_reschedule/${String(unitID)}/${String(objectID)}`
                }
            }// updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Rescheduled by BSO'], Datetime: new Date(`${data.Datetime as string}+04:00`) }, 'The viewing is successfully rescheduled', 'user-viewing-newtime', 'ATTENTION viewing is rescheduled', `/landlord/modal/set_key_bookings_modal_reschedule/${String(unitID)}/${String(objectID)}`)
            break

        case 'complain_viewing_modal':
            callback = (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Completed'] }, 'The complain is successfully saved', 'user-viewing-complaint', 'ATTENTION viewing is waiting for complain')
            break
        case 'lock_pass_viewing_modal':
            callback = (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Approved'] }, 'The lock pass is successfully set', 'user-viewing-approved', `ATTENTION viewing is approved. Lock pass is ${data.DigitalLockPass as string}`)
            break
        case 'lock_pass_viewing_modal_reschedule':
            callback = (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Rescheduled by BSO'], Datetime: store.getState().modal.dateChooseViewing, RescheduleReason: store.getState().modal.rescheduleReason }, 'The lock pass is successfully set', 'user-viewing-newtime', `ATTENTION viewing is approved. Lock pass is ${data.DigitalLockPass as string}`, -2)
            break
        case 'set_key_bookings_modal':
            dispatch(setModalDateChooseViewing(Number(objectID)))
            modal[0].form[0].content[1] = dayjs(store.getState().modal.dateChooseViewing).format('YYYY-MM-DD HH:mm')
            callback = (data: Record<string, unknown>) => createKeyBookingByMagic(Number(unitID), Number(objectID), data)
            break
        case 'set_key_bookings_modal_reschedule':
            // dispatch(setModalDateChooseViewing(Number(objectID)))
            modal[0].form[0].content[1] = dayjs(store.getState().modal.dateChooseViewing).format('YYYY-MM-DD HH:mm')
            callback = (data: Record<string, unknown>) => createKeyBookingByMagic(Number(unitID), Number(objectID), data, -2, 'Rescheduled by BSO')
            break
        case 'reject_offer_modal':
            // TODO set actual notify data for this change
            callback = (data: Record<string, unknown>) => updateOfferByMagic(Number(objectID), { ...data, Statuses: ['Rejected'] }, 'Offer has been rejected', 'user-offer-rejected', 'ATTENTION offer has been rejected')
            break
        case 'require_resubmit_offer_modal':
            callback = (data: Record<string, unknown>) => updateOfferByMagic(Number(objectID), { ...data, Statuses: ['Resubmit required'] }, 'Offer has been rejected', 'user-offer-resubmit', 'ATTENTION offer has been resubmitted')
            break
        case 'negotiate_offer_modal':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = userType === 'Landlord'
                ? (data: Record<string, unknown>) => updateOfferByMagic(Number(objectID), { ...data, Statuses: ['Negotiated by BSO'] }, 'Your price is successfully saved', 'user-offer-negotiate', 'ATTENTION price on offer has been negotiated')
                : saveAgentOfferNegotiate(unitID, objectID)
            break
        case 'check_slip_offer_modal':
            (() => {
                const offer = _.find(state.offers.offers, (row) => row.id === Number(objectID))
                //  console.log(_.cloneDeep(state.documents.documents.filter(doc => ((doc.attributes as any)?.Offer?.data?.id === Number(objectID) && doc.attributes as any)?.Category === 'Deposit Cheque')))
                const document = _.cloneDeep(state.documents.documents)
                    .filter(doc => (doc.attributes as any)?.Offer?.data?.id === Number(objectID) && ['Deposit Cheque', 'Offer Confirmation'].includes((doc.attributes as any)?.Category))
                    .sort((a, b) => b?.id - a?.id)?.[0]
                if (offer != null && document != null) {
                    modal[0].form[0].onClick = () => { window.open(document.attributes?.Link, '_blank') }
                    modal[0].form[1].content = [document.attributes?.Link]
                }
            })()
            callback = () => {
                void dispatch(setUnitSold(Number(unitID)))
                return updateOfferByMagic(Number(objectID), { Statuses: ['Completed'] }, 'Offer has been completed successfully', 'user-offer-completed', '', true)
            }
            // callback = (data: Record<string, unknown>) => updateOfferByMagic(Number(objectID), { Statuses: ['Slip approved'] }, 'Slip is successfully approved', 'user-offer-deposit-approved', 'ATTENTION deposit on offer has been approved', false, true)
            handleReset = () => {
                void (async () => {
                    await dispatch(updateOfferByMagic(Number(objectID), { Statuses: ['Slip rejected'] }, 'Slip has been successfully rejected', 'user-offer-deposit-rejected', 'ATTENTION deposit on offer has been rejected'))
                    await swalSuccess('Slip has been successfully rejected')
                    navigate(-1)
                })()
            }
            break
        case 'change_status_offer_modal':
            callback = (data: Record<string, unknown>) => updateOfferByMagic(Number(objectID), data, 'You have successfully updated status', 'user-offer-change', 'ATTENTION the offer status has changed')
            break
        case 'check_advertisement_links_modal':
        case 'check_advertisement_links_removed_modal':
            initialData = magicFillCheckLinks(+(objectID ?? -1), 'check_advertisement_links_modal')
            modal = [initialData.modal]
            callback = (data: Record<string, unknown>) => setAdvertiseLinksStatus(data, Number(objectID), key)
            break
        case 'check_a_form_modal':
            (() => {
                const advertise = _.find(state.advertises.advertises, (row) => row.id === Number(objectID))

                //  console.log(_.cloneDeep(state.documents.documents.filter(doc => ((doc.attributes as any)?.Offer?.data?.id === Number(objectID) && doc.attributes as any)?.Category === 'Deposit Cheque')))
                const document = _.cloneDeep(state.documents.documents)
                    .filter(doc => (doc.attributes as any)?.Advertise?.data?.id === Number(objectID) && (doc.attributes as any)?.Category === 'A Form')
                    .sort((a, b) => b?.id - a?.id)?.[0]
                if (advertise != null && document != null) {
                    modal[0].form[0].content.push(document.attributes?.Link)
                    modal[0].form[1].content = [document.attributes?.Link]
                }
            })()
            callback = (data: Record<string, unknown>) => checkAFormSwitchAction(data, Number(objectID), Number(unitID), true)
            handleReset = () => {
                navigate(`/landlord/modal/reject_a_form/${Number(unitID)}/${Number(objectID)}`)
            }
            break
        case 'approve_a_form':
            (() => {
                const advertise = _.find(state.advertises.advertises, (row) => row.id === Number(objectID))
                //  console.log(_.cloneDeep(state.documents.documents.filter(doc => ((doc.attributes as any)?.Offer?.data?.id === Number(objectID) && doc.attributes as any)?.Category === 'Deposit Cheque')))
                const document = _.cloneDeep(state.documents.documents)
                    .filter(doc => (doc.attributes as any)?.Advertise?.data?.id === Number(objectID) && (doc.attributes as any)?.Category === 'A Form')
                    .sort((a, b) => b?.id - a?.id)?.[0]
                if (advertise != null && document != null) {
                    modal[0].form[0].content.push(document.attributes?.Link)
                    modal[0].form[1].content = [document.attributes?.Link]
                }
            })()
            callback = (data: Record<string, unknown>) => checkAForm(data, Number(objectID), true)
            break
        case 'reject_a_form':
            (() => {
                const advertise = _.find(state.advertises.advertises, (row) => row.id === Number(objectID))
                //  console.log(_.cloneDeep(state.documents.documents.filter(doc => ((doc.attributes as any)?.Offer?.data?.id === Number(objectID) && doc.attributes as any)?.Category === 'Deposit Cheque')))
                const document = _.cloneDeep(state.documents.documents)
                    .filter(doc => (doc.attributes as any)?.Advertise?.data?.id === Number(objectID) && (doc.attributes as any)?.Category === 'A Form')
                    .sort((a, b) => b?.id - a?.id)?.[0]
                if (advertise != null && document != null) {
                    modal[0].form[0].content.push(document.attributes?.Link)
                    modal[0].form[1].content = [document.attributes?.Link]
                }
            })()
            callback = (data: Record<string, unknown>) => checkAForm(data, Number(objectID), false)
            break
        case 'check_booking_form_modal':
            callback = magicCheckBookingForm(unitID, objectID)
            break
        case 'faq_modal':
            initialData = fillFaqModal(key, objectID ?? '')
            modal = [initialData.modal]
            break
        case 'admin_upload_offer_document_modal':
            initialData = fillAdminOfferDocumentModal(Number(objectID), key)
            modal = [initialData.modal]
            callback = (data: Record<string, unknown>) => uploadOfferDocument(Number(objectID), data, 'You have successfully updated status', 'user-offer-change', 'ATTENTION the offer status has changed')
            break

        // case 'agent_create_unit':
        //     callback = uploadFile
        //     break
        case 'agent_create_unit_property':
            callback = createPropertyForUnit
            break
        case 'agent_create_unit_options':
            callback = createAdditionalOptionsForUnit
            break
        case 'agent_create_unit_marketing':
            callback = createMarketingForUnit
            break
        case 'agent_edit_unit':
            callback = editAgentUnit
            break
        case 'agent_offer_submit_client_info_modal':
            initialData = dispatch(fillSetClientContactInfoModal(key, Number(objectID)))
            modal = [initialData.modal]
            callback = (data: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
                return await setClientContactInfo(Number(objectID), data.email, data.phone, getState().modal.documents[0])
            }
            break
        case 'agent_set_broadcast':
            // callback = createBroadcast
            callback = createBroadcastNew
            break

        case 'submit_deposit_slip_offer_modal':
            initialData = fillDepositSlipOfferModal(modal, String(objectID), initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferPaymentConfirmation(Number(objectID))
            break

        case 'viewing_actions_from_agent':
            callback = null
            break
        case 'offer_actions_from_agent':
            callback = null
            break

        case 'reschedule_viewing_modal_from_agent':
            callback = updateViewingByOtherAgent
            break
        case 'agent_to_agent_make_offer':
            callback = makeOfferAgentToAgent
            break

        case 'agent_to_agent_make_offer_last':
            callback = makeOfferAgentToAgentLastPart
            break

        case 'agent_to_agent_negotiate_offer':
            callback = negotiateOfferAgentToAgent
            break

        case 'agent_to_bso_make_offer_documents':
        case 'agent_to_bso_resubmit_offer_documents':
            callback = makeOfferAgentToBSOClientInfo(Number(unitID), nanToNull(objectID) ?? undefined)
            break
        case 'agent_to_bso_offer_offer_info':
        case 'agent_to_bso_resubmit_offer_info':
            callback = makeOfferAgentToBSOOfferInfo(Number(unitID), Number(objectID))
            break
        case 'agent_to_bso_offer_additional_info':
        case 'agent_to_bso_resubmit_additional_info':
            callback = makeOfferAgentToBSOAdditionalInfo(Number(unitID), Number(objectID))
            break
        case 'profile_modal':
            callback = updateProfile
            break

        default:
            break
    }

    const onLeave = (str?: string): void => {
        return void swalAttentionLeave(swalConfig.attentionLeave.messageModalFooterClose).then(async (result: any) => {
            if (result.isConfirmed) {
                str && str !== '' ? navigate(str) : navigate(-1)
            }
        })
    }

    const onCloseForAdvertiseModal = () => {
        localStorage.removeItem(USER_ADVERTISE_DAYS)
        dispatch(setDebitMarketingCredits(0))
        dispatch(setNotEnoughMarketingCredits(0))
        dispatch(setShowButtonBuyCoinsOnAdvertise(false))
        navigate('/agent/units/nav')
    }

    if (key === 'update_emirates_front_together' && objectID === 'first') return <TutorialLoadDocuments />
    return (
        <>
            <div className="page d-flex fs-5">
                <div className="content w-100 mw-450px mx-auto ">

                    <div className="card d-flex mh-100 ">
                        <div className="card-header shadow-sm " style={{ paddingTop: userDevice === 'IPhone' ? '45px' : '14px', paddingBottom: '14px' }}>
                            <CustomModalHeader modal={modal} onLeave={onLeave} onCloseForAdvertiseModal={onCloseForAdvertiseModal} />
                        </div>
                        <div className="card-body card-scroll h-50" style={
                            modal[0].overflowScroll === true
                                ? { maxHeight: `calc(100vh - ${userDevice === 'IPhone' ? '101' : '70'}px)` }
                                : {}
                        }>
                            <CustomValidatorHook id={modalID ?? ''} fromPage={unitID} unitID={unitID} objectID={objectID} callback={callback} handleReset={handleReset} initialState={initialData.initialState} modals={modals}>
                                <CustomFieldsHook modal={modal} isAuth={false} unitID={unitID} objectID={objectID} />
                            </CustomValidatorHook>
                            <div style={{ height: '70px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
