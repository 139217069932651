import { type AppDispatch, type RootState } from '../../../store'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import { uploadMultipleForOffers } from '../../documentsActions/upload/uploadMultipleForOffers'
import QueryString from 'qs'
import { offersSlice } from '../../../slices/offersSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { dataFetchingFinish, modalSlice } from '../../../slices/modalSlice'
import { writeAgentToBSOOfferCommunicationStorageInfo } from './agentToBSOOfferCommunicationStorage'
import {fetchDocuments} from "../../documentActions";

export const makeOfferAgentToBSOClientInfo = (unitID: number, offerID?: number) => (data: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log(data, 'data')
    dispatch(modalSlice.actions.dataFetchingStart())
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY)
        const unit = getState().units.units.find((unitItem) => unitItem.id === unitID)
        const offer = getState().offers.offers.find((offerItem) => offerItem.id === offerID)
        if (unit != null) {
            const isCommercial = data.offer_is_commercial === true
            const isSale = unit.attributes?.Marketing?.Use === 'For sale'

            let type: Record<string, any>

            if (isCommercial) {
                type = {
                    __component: isSale ? 'offer.sale-commercial' : 'offer.commercial',
                    BusinessName: data.Type.BusinessName,
                    BusinessOwnerName: data.Type.BusinessOwnerName,
                    BusinessActivity: data.Type.BusinessActivity,
                    EstablishmentDate: data.Type.EstablishmentDate ? data.Type.EstablishmentDate : null
                    //   bodyClient.Type.BusinessOwnerName bodyClient.Type.BusinessActivity bodyClient.Type.EstablishmentDate
                }
            } else {
                type = {
                    __component: isSale ? 'offer.sale' : 'offer.residential',
                    FirstName: data.Type.FirstName,
                    LastName: data.Type.LastName,
                    Country: data.Type.Country
                }
            }
            data.offerID = offerID
            const loadDocs = await dispatch(uploadMultipleForOffers(data, 'agent_to_bso_offer'))

            const property = unit.attributes?.Property?.data
            const name = property?.attributes?.Name ?? ''
            const location = property?.attributes?.Area ?? ''
            const unitNumber = unit?.attributes?.Number ?? ''

            const bodyObject = {
                method: offerID == null ? 'POST' : 'PUT',
                id: offerID ?? undefined,
                collection: 'offers',
                query: QueryString.stringify({
                    populate: ['Type', 'Documents', 'User', 'Unit']
                }, {
                    encodeValuesOnly: true
                }),

                body: {
                    Type: [{
                        ...(offer?.attributes?.Type?.[0] ?? {}),
                        ...type
                    }],
                    ProcessStartDate: offerID == null ? new Date() : undefined,
                    Statuses: ['In-process'],
                    Name: `${unitNumber} <-> ${name} <-> ${location}`,
                    Notify: 'Both',
                    Unit: unit.id,
                    User: getState().user.currentUser.id,
                    Documents: offerID == null ? [...loadDocs] : undefined
                }
            }
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${String(jwt)}`
                },
                body: JSON.stringify(bodyObject)
            })
            const responseJSON = await response.json()
            // await dispatch(fetchDocuments(true))
            if (responseJSON.success === true) {
                if (responseJSON.response.error != null) {
                    dispatch(offersSlice.actions.offersFetchingError(getErrorss(responseJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
                } else {
                    dispatch(offersSlice.actions.addOffer(responseJSON.response.data))
                    dispatch(dataFetchingFinish())
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    writeAgentToBSOOfferCommunicationStorageInfo(unitID, Number(responseJSON?.response?.data?.id), 'client_info', offerID == null ? 'create' : 'resubmit')
                    return {
                        textNavigate: `/agent/modal/${offerID == null ? 'agent_to_bso_offer_offer_info' : 'agent_to_bso_resubmit_offer_info'}/${unitID}/${String(responseJSON?.response?.data?.id)}`,
                        isSuccessful: true,
                        textNotification: 'Add additional information',
                        title: 'Offer has been created'
                    }
                }
            }
        }
    } catch (err: any) {
        console.error(`Error in makeOfferAgentToBSOClientInfo ${String(err.message)}`)
    }
    dispatch(modalSlice.actions.dataFetchingFinish())
    return {
        isSuccessful: false,
        textNotification: 'Something went wrong. Try again later'
    }
}
