import React, { useState } from 'react'

import Approved from '../../../../functions/agent/statuses/Approved'
import Rejected from '../../../../functions/agent/statuses/Rejected'

import NotAdded from '../../../../functions/agent/statuses/NotAdded'
import InProcessScheduler from '../../../../functions/agent/statuses/InProcessScheduler'
import { mounthes } from '../../../../functions/agent/date/timeString'
import { checkIsHaveEmiratesAndExpires, checkIsHavePassportAndExpires, checkIsHaveReraAndExpires } from '../../../../functions/agent/documents/checkIsHaveDocumentsAndExpires'
import { type Document } from '../../../../store/slices/documentSlice'
import CopyText from "../../../shared/elements/CopyText";
import downloadBlobByURL from "../../../../functions/utils/downloadBlobByURL";
import { USER_ID_KEY } from '../../../../urls'

export function docStatus(arrayStatuses: string[]): JSX.Element {
    if (arrayStatuses.includes('Approved')) return <Approved />
    else if (arrayStatuses.includes('In-process')) return <InProcessScheduler />
    else if (arrayStatuses.includes('Rejected')) return <Rejected />
    else {
        return <div
            className={'badge badge-light-danger border border-danger'}
        >
            {arrayStatuses[0]}
        </div>
    }
}

interface IProps {
    documents: Document[]
}

export default function AgentProfileDocuments({ documents }: IProps): JSX.Element {
    let agentDocs: any = []
    if (Array.isArray(documents)) {
        agentDocs = documents
    }
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const emiratesExp = checkIsHaveEmiratesAndExpires(agentDocs).expireFile
    const emiratesIdDoc = checkIsHaveEmiratesAndExpires(agentDocs).file
    const passportDoc = checkIsHavePassportAndExpires(agentDocs).file
    const passportExp = checkIsHavePassportAndExpires(agentDocs).expireFile
    const reraCardDoc = checkIsHaveReraAndExpires(agentDocs).file
    const reraExp = checkIsHaveReraAndExpires(agentDocs).expireFile

    const verifiedDocs = []
    if (emiratesIdDoc) {
        verifiedDocs.push(emiratesIdDoc)
    } if (passportDoc) {
        verifiedDocs.push(passportDoc)
    }

    const verifiedDoc = verifiedDocs.find((el) => el?.attributes?.Statuses?.includes('Approved')) ?? verifiedDocs.find((el) => el?.attributes?.Statuses?.includes('Rejected')) ?? verifiedDocs.find((el) => el?.attributes?.Statuses?.includes('In-process')) ?? verifiedDocs.find((el) => el?.attributes?.Statuses?.includes('Archived'))
    const [documentsLoading, setDocumentsLoading] = useState(false)
    return (
        <>
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 w-100 mb-4 row g-0 d-flex flex-row">
                <div className="col-9 fs-5 g-0 d-flex flex-column justify-content-center">
                    <div className="">
                        <span className='mb-2 fs-4 fw-bold counted'>Emirates ID or Passport :</span>
                        <div className="fw-semibold fs-6 text-gray-600">
                            {emiratesExp && 'Expiry Date' || passportExp && 'Expiry Date'}
                        </div>
                        {
                            emiratesIdDoc?.attributes?.Type[0]?.Number != null
                                ? <div className="fw-semibold fs-6 text-gray-600">
                                    Emirates ID Number :
                                </div>
                                : <></>
                        }
                        {
                            passportDoc?.attributes?.Type[0]?.Number != null
                                ? <div className="fw-semibold fs-6 text-gray-600">
                                    Passport Number :
                                </div>
                                : <></>
                        }
                    </div>
                </div>
                <div className="col-3 g-0 d-flex flex-column align-items-end">
                    <div className='mb-1'>   {verifiedDoc ? docStatus(verifiedDoc?.attributes?.Statuses) : <NotAdded />}</div>

                    <div className="fw-semibold fs-7 lh-lg text-gray-600">
                        {emiratesExp && new Date(emiratesExp) && `${mounthes[new Date(emiratesExp).getMonth()].slice(0, 3)} ${new Date(emiratesExp).getMonth().toString().padStart(2, '0')}, ${new Date(emiratesExp).getFullYear().toString()}` || passportExp && new Date(passportExp) && `${mounthes[new Date(passportExp).getMonth()].slice(0, 3)} ${new Date(passportExp).getDate().toString().padStart(2, '0')}, ${new Date(passportExp).getFullYear().toString()}`}
                    </div>
                    {
                        emiratesIdDoc?.attributes?.Type[0]?.Number != null
                            ? <div className="fw-semibold fs-7 lh-lg text-gray-600">
                                <CopyText text={emiratesIdDoc?.attributes?.Type[0]?.Number} />
                            </div>
                            : <></>
                    }
                    {
                        passportDoc?.attributes?.Type[0]?.Number != null
                            ? <div className="fw-semibold fs-7 lh-lg text-gray-600">
                                <CopyText text={passportDoc?.attributes?.Type[0]?.Number} />
                            </div>
                            : <></>
                    }
                </div>

            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 w-100 mb-4 row g-0 d-flex flex-row">
                <div className="col-9 fs-5 g-0 d-flex flex-column justify-content-center">
                    {/* mb-2  */}
                    <span className='fs-4 fw-bold counted'>RERA License :</span>
                    <div className="fw-semibold fs-6 text-gray-600">
                        {reraExp && 'Expiry Date :'}
                    </div>
                    {
                        reraCardDoc?.attributes?.Type[0]?.Number != null
                            ? <div className="fw-semibold fs-6 text-gray-600">
                                RERA Licence Number :
                            </div>
                            : <></>
                    }
                </div>
                <div className="col-3 g-0 d-flex flex-column align-items-end">

                    <div className='mb-1'>  {reraCardDoc ? docStatus(reraCardDoc?.attributes?.Statuses) : <NotAdded />}</div>

                    <div className="fw-semibold fs-7 lh-lg text-gray-600">
                        {reraExp && new Date(reraExp) && `${mounthes[new Date(reraExp).getMonth()].slice(0, 3)} ${new Date(reraExp).getDate().toString().padStart(2, '0')}, ${new Date(reraExp).getFullYear().toString()}`}
                    </div>
                    {
                        reraCardDoc?.attributes?.Type[0]?.Number != null
                            ? <div className="fw-semibold fs-7 lh-lg text-gray-600">
                                <CopyText text={reraCardDoc?.attributes?.Type[0]?.Number} />
                            </div>
                            : <></>
                    }
                </div>

            </div>
            {Number(userID) === Number(process.env.REACT_APP_BSO_USER_ID) && <div className={'row'}>
                <div className={'col'}>
                    <div className={'btn btn-bso'}
                        onClick={() => {
                            if (!documentsLoading) {
                                setDocumentsLoading(true)
                                const promises: Array<Promise<void>> = []
                                if (emiratesIdDoc?.attributes?.LinkFront != null) {
                                    promises.push(downloadBlobByURL(emiratesIdDoc?.attributes?.LinkFront, `Emirates-ID-Front`))
                                }
                                if (emiratesIdDoc?.attributes?.LinkBack != null) {
                                    promises.push(downloadBlobByURL(emiratesIdDoc?.attributes?.LinkBack, `Emirates-ID-Back`))
                                }
                                if (passportDoc?.attributes?.Link != null) {
                                    promises.push(downloadBlobByURL(passportDoc?.attributes?.Link, `Passport`))
                                }
                                if (reraCardDoc?.attributes?.Link != null) {
                                    promises.push(downloadBlobByURL(reraCardDoc?.attributes?.Link, `RERA`))
                                }
                                Promise.all(promises)
                                    .finally(() => {
                                        setDocumentsLoading(false)
                                    })
                            }
                        }}
                    >
                        {documentsLoading
                            ? <div className={'spinner-border spinner-border-sm me-2'} />
                            : <></>
                        } Download documents
                    </div>
                </div>
            </div>}

        </>
    )
}
