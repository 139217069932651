import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import { type IChat } from '../../../../types'
import useUserType from '../../../../hooks/UseUserType'
import ChatUtils from '../../../../functions/chats/ChatUtils'
import Avatar from '../../elements/Avatar'
import { useAppSelector } from '../../../../hooks/redux'
import Icon from '../../../../app/icon/Icon'
import Swal from 'sweetalert2'
import { animationOptions, swalOptions } from '../../../../functions/swalOptions'
import { ava_blank, bso_logo_512 } from '../../../../urls'
import { type RootState } from '../../../../store/store'
import { type User } from '../../../../store/slices/userSlice'
import { type Unit } from '../../../../store/slices/unitsSlice'
import { setFilterNavigate } from '../../../../store/slices/scheduleSlice'
import { useDispatch } from 'react-redux'
import AdminChatActions from "./AdminChatActions";

interface ChatHeaderProps {
    chat: IChat
    loading: boolean
    isOffer: boolean
    scrollRef: React.RefObject<HTMLDivElement>
}

export const BSO_STOP_SHOWING_FAQ_POPUP = 'bso-stop-showing-faq-popup'

const ChatHeader = ({ chat, loading, scrollRef }: ChatHeaderProps): JSX.Element => {
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userType, userTypeLowercase } = useUserType()
    const profileLink = `/${userType.toLowerCase()}/profile/${Number(chat?.User?.id)}/chat?chatId=${String(chat?.id)}`
    const [searchParams, setSearchParams] = useSearchParams()
    const from = searchParams.get('from')

    // useEffect(() => {
    //     // console.log(chat, ChatUtils.isBSOSupport(chat), window.localStorage.getItem(BSO_STOP_SHOWING_FAQ_POPUP) == null)
    //     if (ChatUtils.isBSOSupport(chat) && window.localStorage.getItem(BSO_STOP_SHOWING_FAQ_POPUP) == null) {
    //         void Swal.fire({
    //             ...swalOptions('info'),
    //             title: 'Have you checked frequently asked questions?',
    //             html: 'Check if your question is answered already',
    //             iconHtml: '<div id="lottie-icon-Info"></div>',
    //             didOpen: () => {
    //                 const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
    //                 if (lottieContainer != null) {
    //                     lottie.loadAnimation({
    //                         container: lottieContainer,
    //                         renderer: 'svg',
    //                         ...{ ...animationOptions, animationData: require('../../../../animations/Info.json') }
    //                     })
    //                 }
    //             },
    //             showConfirmButton: true,
    //             confirmButtonText: 'Go to F.A.Q.',
    //             showDenyButton: true,
    //             denyButtonText: 'Continue to chat',
    //             input: 'checkbox',
    //             inputPlaceholder: 'Don\'t ask me again',
    //             returnInputValueOnDeny: true
    //         }).then((data) => {
    //             console.log(data)
    //             if (data.value === 1) {
    //                 window.localStorage.setItem(BSO_STOP_SHOWING_FAQ_POPUP, 'true')
    //             }
    //             if (data.isConfirmed) {
    //                 navigate(`/${userTypeLowercase}/faq`)
    //             }
    //         })
    //     }
    // }, [chat.User != null])
    // console.log(chat, 'chat')

    return (
        <>
            <div className={'w-100 shadow-sm bg-white'} style={{ zIndex: '1000' }}>
                {userDevice === 'IPhone'
                    ? <div className="h-25px"></div>
                    : <></>
                }
                <div className={'w-100 d-flex justify-content-between align-items-center p-4 pt-6'}
                    style={{ zIndex: '100' }}
                >
                    <Icon
                        name={'arrow_circle_left'}
                        className={'text-muted fs-4x'}
                        onClick={() => {
                            switch (from) {
                                case 'single-message':
                                    navigate(-2)
                                    break
                                case 'create-broadcast':
                                    dispatch(setFilterNavigate('broadcasts'))
                                    navigate(`/agent/schedule`)

                                    break
                                default:
                                    navigate(-1)
                                    break
                            }
                        }}
                    />

                    {<>
                        <div className={'d-flex gap-4 align-items-center'}>
                            {!loading
                                ? <h2 className={'fw-bold my-2'}>
                                    {/* !ChatUtils.isBSOBroadcast(chat) ? (chat?.User?.Name ?? '') : 'BSO Broadcast' */}
                                    {
                                        ChatUtils.isBSOBroadcast(chat)
                                            ? 'BSO Broadcast'
                                            : ChatUtils.isBSOSupport(chat)
                                                ? 'BSO Support'
                                                : chat?.User?.Name ?? ''
                                    }
                                </h2>
                                : <></>
                            }
                        </div>
                        <div className={'d-flex gap-4 align-items-center'}>
                            { /* !ChatUtils.isBSOSupport(chat)
                            ? */<>
                                    {!loading
                                        ? <Link to={ChatUtils.isBSOBroadcast(chat) || ChatUtils.isBSOSupport(chat) ? '' : profileLink}>
                                            <Avatar
                                                image={!ChatUtils.isBSOBroadcast(chat) ? chat?.User?.Photo : bso_logo_512}
                                                size={'25px'}
                                            />
                                        </Link>
                                        : <></>
                                    }
                                </>
                                /* : <Icon
                                    name={'help'}
                                    className={'text-muted fs-4x'}
                                    onClick={() => {
                                        navigate(`/${userTypeLowercase}/faq`)
                                    }}
                                /> */
                            }
                        </div>
                    </>}

                </div>
                <AdminChatActions scrollRef={scrollRef} />
                {/* <div className={'p-4 bg-white d-flex align-items-center justify-content-between'}>
                    <Icon name={'contact_support'} size={'4x'}/>
                    <div className={'fs-1 fw-bold'}>F.A.Q.</div>
                    <Icon name={'chevron_down'} size={'4x'}/>
                </div> */}
            </div>
            {/* <div className="" style={{ height: userDevice === 'IPhone' ? '88px' : '63px' }}></div> */}
        </>
    )
}

export default ChatHeader
