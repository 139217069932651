import React from 'react'

interface IProps {
    register: any
    errors: any
    row: any
    clearErrors?: any
    setError?: any
}

export default function AreaInput({ register, errors, row, clearErrors, setError }: IProps): JSX.Element {

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        const linkRegExp = /https?:\/\/[^\s]+/g;

        if (row.regexp != null && linkRegExp.test(value)) {
            setError(row.id, {
                type: 'validate',
                message: 'Links are not allowed in this field',
            });
        } else {
            if (errors[row.id]?.type === 'validate') {
                clearErrors(row.id);
            }
        }
    };


    return (
        <div className="fv-row mb-2 m-0 p-0 w-100 fv-plugins-icon-container">
            <label className={
                (row.required)
                    ? 'required form-label mb-2 fw-bold text-dark text-uppercase'
                    : 'form-label fw-bold text-dark text-uppercase'
            }>
                {row.content?.[0]}
            </label>
            <textarea
                {...register(row.id, {
                    required: row.required ? `Field "${row.content?.[0]}" is required` : undefined,
                    minLength: 2,
                    maxLength: row.maxLength ?? 256,
                    validate: (value: string) => {
                        const linkRegExp = /https?:\/\/[^\s]+/g;
                        if (row.regexp != null) {
                            return !new RegExp(linkRegExp).test(value) || 'Links are not allowed in this field';
                        }
                        return true;
                    }
                })
                }
                className="form-control form-control-solid form-control-lg"
                rows={5}
                placeholder={row.content?.[1]}
                defaultValue={row.defaultValue}
                onChange={handleChange}
            />
            {
                errors[row.id] && (
                    <div className="text-danger">{errors[row.id].message}</div>
                )
            }
            {
                errors[row.id] && errors[row.id].type === 'minLength' && (
                    <div className="text-danger">{`${row.content?.[0]} can not be less than 3 characters `} </div>
                )
            }
            {errors[row.id] && errors[row.id].type === 'maxLength' &&
                (
                    <div className="text-danger">{`${row.content?.[0]} cannot exceed  ${row.maxLength ?? 256} characters more `} </div>
                )
            }
            {
                errors[row.id] && errors[row.id].type === 'validate' && (
                    <div className="text-danger">{`${row.content?.[0]} must be not contain links`}</div>
                )
            }
        </div>
    )
}
