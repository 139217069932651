import React from 'react'

import {
    setButtonDriverAddOnsParent,
    setButtonDriverExtendLocation,
    setIsAreasOpen

} from '../../../../store/slices/modalSlice'
import AddOnServicesInfoDrawer from './AddOnServicesInfoDrawer'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'

import { mainSwalDeal } from '../../../../functions/swalOptions'
import { setNotEnoughMarketingCredits } from '../../../../store/slices/advertisesSlice'
import { makePositiveOrUnchanged } from '../../../../functions/agent/date/makePositiveOrUnchanged'
import { areaExpansionImage, USER_AREAS_EXTEND_COUNT } from '../../../../urls'
import { type ISettings } from '../../../../store/slices/userSlice'
import { useNavigate } from 'react-router-dom'
import AddOnServicesAreaInfo from './AddOnServicesAreaInfo'
import AddOnServicesCardWithoutCarousel from './AddOnsServicesCardWithoutCarousel'
import { type AddOnsServiceSliderCardOptions } from '../../../../types'

export default function AddOnsServiceArea(): React.ReactElement {
    const isAreasOpen = useAppSelector((state: RootState) => state.modal.isAreasOpen)
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector((state) => state.user.currentUser)
    const currentUserMarketingCredits = Number((currentUser?.Settings as ISettings[])?.[0]?.MarketingCredits) ?? 0
    const navigate = useNavigate()



    const config: AddOnsServiceSliderCardOptions = {
        title: 'Area expansion',
        description: ' Utilize our in-app currency, Bricks, to unlock the Area Expansion Add-On and maximize your real estate potential.',
        imageUrl: areaExpansionImage,
        purchaseText: 'Selects areas',
        onPurchase:
            (amount) => {
                const priceBricks = amount === 10 ? 55 : amount === 5 ? 35 : amount === 3 ? 25 : amount * 10
                if (priceBricks > currentUserMarketingCredits) {
                    void mainSwalDeal('You don\'t have enough <strong>BRICKS</strong> , do you want to buy more for area expansion', undefined, 'Yes, I do', 'Not now').then(async (result: any) => {
                        if (result?.isConfirmed === true) {
                            dispatch(setNotEnoughMarketingCredits(makePositiveOrUnchanged((priceBricks) - currentUserMarketingCredits)))
                            dispatch(setButtonDriverAddOnsParent(true))
                            dispatch(setButtonDriverExtendLocation(false))
                        }
                    })
                } else {

                    const storageCountAreasExtend = window.localStorage.getItem(USER_AREAS_EXTEND_COUNT)
                    const [chooseExtAreaIdUnit, countAreasExtend] = storageCountAreasExtend
                        ? (typeof storageCountAreasExtend === 'string' ? storageCountAreasExtend.split(' - ') : [undefined, '3'])
                        : [undefined, '3']


                    navigate(`/agent/modal/agent_settings_for_areas_extension/${chooseExtAreaIdUnit === 'undefined' || chooseExtAreaIdUnit === undefined ? 'addons' : Number(chooseExtAreaIdUnit) ? chooseExtAreaIdUnit : 'addons'}/${currentUser.id as number}`)

                }
            },
        onInfo: () => {
            dispatch(setIsAreasOpen(true))
        },
        showTermsAndConditions: false,
        options: [
            {
                product: {
                    prefix: 'Pack',
                    amount: 1,
                    unit: 'Area'
                },
                price: {
                    amount: 10,
                    unit: 'Bricks',
                    postfix: '/ At once'
                }
            },
            {
                product: {
                    prefix: 'Pack',
                    amount: 3,
                    unit: 'Areas'
                },
                price: {
                    amount: 25,
                    unit: 'Bricks',
                    postfix: '/ At once'
                }
            },
            {
                product: {
                    prefix: 'Pack',
                    amount: 5,
                    unit: 'Areas'
                },
                price: {
                    amount: 35,
                    unit: 'Bricks',
                    postfix: '/ At once'
                }
            },
            {
                product: {
                    prefix: 'Pack',
                    amount: 10,
                    unit: 'Areas'
                },
                price: {
                    amount: 55,
                    unit: 'Bricks',
                    postfix: '/ At once'
                }
            }
        ]
    }

    return (
        <>
            <AddOnServicesCardWithoutCarousel key={'Areas services'} img={areaExpansionImage} config={config} isLoading={false} />
            <AddOnServicesInfoDrawer
                isOpen={isAreasOpen}
                onClose={() => {
                    dispatch(setIsAreasOpen(false))
                }}
                key={'Areas info'}
            >
                <AddOnServicesAreaInfo />
            </AddOnServicesInfoDrawer>
        </>
    )
}
