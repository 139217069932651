
import React, { useState } from 'react'
import { Select } from 'antd'
import { Controller } from 'react-hook-form'

import { store, type RootState } from '../../../store/store'
import { type Unit } from '../../../store/slices/unitsSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { setExtendAreas } from '../../../store/slices/areasSlice'
import { USER_AREAS_EXTEND_COUNT, USER_CHOOSE_EXTEND_AREA } from '../../../urls'


export interface ICountUnits {
    value: string
    countUnits: number
}

export default function CustomSelectAreasExtend({ register, control, data, currentUser, unitID }: any): JSX.Element {
    const initValues = Array.from(new Set(data.defaultValue.map((area: string) => area)));
    let units = useAppSelector(
        (state: RootState) => state.units.units
    )

    units = [...units]
        .filter((unit) => unit.attributes?.User?.data?.id === Number(process.env.REACT_APP_BSO_USER_ID ?? '57') && unit.attributes?.ManagedBy === 'BSO' && !unit?.attributes?.Statuses?.includes('Sold') && !unit?.attributes?.Statuses?.includes('Drafted') && !unit?.attributes?.Statuses?.includes('Archived') && Boolean(unit?.attributes?.Marketing))


    const dispatch = useAppDispatch()
    const whatIsNotThereAreaLS = window.localStorage.getItem(USER_CHOOSE_EXTEND_AREA)
    const storageCountAreasExtend = window.localStorage.getItem(USER_AREAS_EXTEND_COUNT)
    let whatIsNotThereArea: null | string = null

    const [chooseExtAreaIdUnit, countAreasExtend] = storageCountAreasExtend
        ? (typeof storageCountAreasExtend === 'string' ? storageCountAreasExtend.split(' - ') : [undefined, '3'])
        : [undefined, '3']
    if (chooseExtAreaIdUnit && Number(chooseExtAreaIdUnit) === Number(unitID)) {
        const area = units?.filter((u) => u.id === Number(unitID))?.[0]?.attributes?.Property?.data?.attributes?.Area

        if (area === whatIsNotThereAreaLS) {
            whatIsNotThereArea = area ?? whatIsNotThereAreaLS ?? null
            if (whatIsNotThereArea && !initValues.includes(whatIsNotThereArea)) {
                //  initValues = [...initValues, whatIsNotThereArea]
            }
        }
    }

    const areasExtentionAmount = currentUser?.AreasExtentionAmount ?? countAreasExtend ?? 0

    const countUnitsInArea = (): ICountUnits[] => {
        let areas = store.getState().app.config?.all?.Area ?? [];
        areas = areas.filter((area: string) => area !== 'All areas' && !data.defaultValue.includes(area));
        areas = areas.concat(data.defaultValue);
        if (whatIsNotThereArea && areas.includes(whatIsNotThereArea)) {
            areas = [...data.defaultValue, whatIsNotThereArea];
        }

        const result: ICountUnits[] = [];
        for (const key of areas) {
            const newObj = { value: key, countUnits: 0 };
            result.push(newObj);
        }

        [...units]?.reduce((result: ICountUnits[], unit: Unit) => {
            for (const iterator of result) {
                if (unit?.attributes?.Property?.data?.attributes?.Area === iterator.value) {
                    iterator.countUnits += 1;
                }
            }
            return result;
        }, result);


        return result.sort((a, b) => {
            if (a.value === whatIsNotThereArea) return -1;
            if (b.value === whatIsNotThereArea) return 1;

            if (b.countUnits !== a.countUnits) {
                return b.countUnits - a.countUnits;
            }

            return a.value.localeCompare(b.value);
        });
    };



    const optionsMapped = (countUnitsInArea() || []).map((el: ICountUnits, index: number) => {
        return {
            key: `${el.value}`,
            value: el?.value,
            label: (
                <>
                    <div className={'d-flex justify-content-between d-block '}>
                        <span className={data.defaultValue.includes(el.value) ? 'd-flex  flex-row d-block' : ''}>
                            {el.value}
                        </span>

                        {el.countUnits && el.countUnits !== 0
                            ? (

                                <span className='badge border border-primary badge-light-primary  fs-7 mx-2' aria-label={el.value}>
                                    <span className='fs-6 me-1'>{el.countUnits} </span>  Unit(s)
                                </span>
                            )
                            : null}

                    </div>

                    {data.defaultValue.includes(el.value) ? <div className='badge border border-success badge-light-success  fs-8 me-2'>You have this area </div> : null}

                    {el.value === whatIsNotThereArea
                        ? <div className='badge border border-danger badge-light-danger  fs-7 me-2'>This is your choose area </div>
                        : null}
                </>
            )

        }
    })

    const [areas, setAreas] = useState<any[]>(initValues)

    const handleSelectChange = (selectedValues: any) => {
        const filteredValues = selectedValues.filter((value: any) => !data.defaultValue.includes(value))
        if (whatIsNotThereArea && selectedValues.includes(whatIsNotThereArea)) {
            filteredValues.push(whatIsNotThereArea)
        }

        if (filteredValues.length > areasExtentionAmount) {
            filteredValues.splice(areasExtentionAmount)
        }
        setAreas([...data.defaultValue, ...filteredValues])
        dispatch(setExtendAreas(filteredValues))
    }

    return (
        (
            <Controller
                name={data.id}
                control={control}
                rules={data.required
                    ? {
                        required: `${data.content?.[0]} is required`,
                        validate: (values) => {
                            const selectedCount = values.filter((value: any) => !data.defaultValue.includes(value)).length
                            if (selectedCount < areasExtentionAmount) {
                                if (selectedCount < 5) {
                                    return `Please select exactly ${areasExtentionAmount} area(s)`
                                } else {
                                    return `Please select ${areasExtentionAmount - selectedCount} more area(s)`
                                }
                            }
                            return true
                        }

                    }
                    : {}}
                render={({ field }) => (
                    <Select
                        popupClassName="drp-style"
                        listHeight={325}
                        placeholder={data.content && data.content[1]}
                        className="d-block"
                        {...field}
                        size={'large'}
                        options={optionsMapped}
                        onChange={(value) => { handleSelectChange(value); field.onChange(value) }}
                        value={areas}
                        mode={'multiple'}
                        showSearch={false}

                    >

                        {data.defaultValue.map((el: string) => <Select.Option value={el}>{el}</Select.Option>)}
                        {field.value === '' && (
                            <Select.Option value="">{data.content[1]}</Select.Option>
                        )}
                    </Select>
                )}
            />

        )

    )
}
