
import React from 'react'

import { Collapse } from 'antd'
import Icon from '../../../icon/Icon'
import Markdown from 'react-markdown'
const { Panel } = Collapse

export default function DescriptionCreateAdLinks(): JSX.Element {
    return (
        // <div className="mb-4">
        //     <Collapse bordered={false} >
        //         <Panel
        //             showArrow={false}
        //             header={
        //                 <span className="card-title justify-end fs-3 fw-bold fw-bold p-0 m-0 ">
        //                     <Icon
        //                         name={'help'}
        //                         className={'text-dark fs-4x me-2'}
        //                     />
        //                     Instruction</span>
        //             }
        //             key="1"
        //         >
        //             <div className="px-2 mt-2 mb-8">
        //                 <h1 className="text-center my-2">Instruction</h1>
        //                 <div className="text-gray-400 fs-3 ps-4 my-2">
        //                     1. After you receive unit documents you can create advertisement posts with this unit on 3-party platforms like Dubizzle, Bayut, Property Finder, etc.
        //                 </div>
        //                 <div className="text-gray-400 fs-3 ps-4">
        //                     2. After you published all needed advertisements please get on each platform Trakheesi Number of advertisement publication (you can get info about how you can get Trakheesi Number in tech support of the platform where you publish your ads)
        //                 </div>
        //                 <div className="text-gray-400 fs-3 ps-4 mb-8">
        //                     3. Please fill form below for each advertisement that you published. We need from you such info like the name of the source of the advertisement (like Dubizzle, Bayut e.t.c.), the link of the posted ad, and the Trakheesi Number of the ad.
        //                 </div>
        //             </div>
        //         </Panel>
        //     </Collapse>
        // </div>

        <div className="mb-4">
            <div className={'rounded rounded-sm bg-gray-300 p-4'}>
                <label className={'form-label fw-bold fs-5 text-dark m-0 d-flex align-items-center text-uppercase '}>
                    <Icon name={'help'} className={'fs-2x text-info me-1'} />
                    Instruction
                </label>
                <div className='fw-semi fs-5  lh-1 mt-2'>
                    <Markdown>
                        1. After you receive unit documents you can create advertisement posts with this unit on 3-party platforms like Dubizzle, Bayut, Property Finder, etc.
                    </Markdown>
                    <Markdown>
                        2. After you published all needed advertisements please get on each platform Trakheesi Number of advertisement publication (you can get info about how you can get Trakheesi Number in tech support of the platform where you publish your ads)
                    </Markdown>
                    <Markdown>
                        3. Please fill form below for each advertisement that you published. We need from you such info like the name of the source of the advertisement (like Dubizzle, Bayut e.t.c.), the link of the posted ad, and the Trakheesi Number of the ad.
                    </Markdown>
                </div>
            </div>
        </div>
    )
}
