export const filterVacantDateForAdvertise = (arr: any): Array<{ id: number; daysLeft: number; EndDate: string; VacantDate: string }> => {
    const currentDate = new Date();
    
    const processedArr = arr.map((item: any) => {
        const endDate = item.attributes.EndDate ? new Date(item.attributes.EndDate as string) : null;
        const updatedAt = item.attributes.updatedAt ? new Date(item.attributes.updatedAt as string) : null;
        if (endDate != null) {
            const vacantDate = new Date(endDate);
            vacantDate.setDate(vacantDate.getDate() + 1); 
            // Вычисляем количество дней до EndDate
            const timeDiff = vacantDate.getTime() - currentDate.getTime(); 
            const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Кол-во дней до VacantDate

            return {
                id: item.id,
                daysLeft: daysLeft > 0 ? daysLeft : 0,
                EndDate: item.attributes.EndDate as string,
                VacantDate: vacantDate.toISOString().split('T')[0],
                updatedAt
            };
        } else {
            return {
                id: item.id,
                daysLeft: 0,
                EndDate: 'TBA',
                VacantDate: 'TBA',
                updatedAt
            };
        }
    });

    // Сортируем по EndDate: если TBA, отправляем в конец; если обе записи с TBA, сортируем по updatedAt
    return processedArr.sort((a: any, b: any) => {
        if (a.EndDate === "TBA" && b.EndDate === "TBA") {

            return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
        } else if (a.EndDate === "TBA") {
            return 1; 
        } else if (b.EndDate === "TBA") {
            return -1;
        } else {
            return new Date(a.EndDate).getTime() - new Date(b.EndDate).getTime();
        }
    });
};


export const getNextAvailableAdvert = (advertiseReserveDates: any, waitingList: any): any => {
    for (const ad of advertiseReserveDates) {
        const adExistsInWaitingList = waitingList.some((waitingItem: any) => waitingItem.adId === ad.id);
        if (!adExistsInWaitingList) {
            return ad;
        }
    }
    return null;
};