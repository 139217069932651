import React, { useState } from 'react'
import { type User } from '../../../../store/slices/userSlice'
import useUserType from '../../../../hooks/UseUserType'
import { useNavigate } from 'react-router'
import { openChatByUserID } from '../../../../store/actionsCreators/chatActions'
import { useAppDispatch } from '../../../../hooks/redux'
import Avatar from '../../elements/Avatar'
import Icon from '../../../../app/icon/Icon'
import { store } from '../../../../store/store'
import {nanoid} from "nanoid";

interface ProfileCardProps {
    user: User
    showUserType?: boolean
}
const ProfileCard = ({ user, showUserType = false }: ProfileCardProps): JSX.Element => {
    const { userType } = useUserType()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const [areasExpanded, setAreasExpanded] = useState(false)
    const maxAreasDisplayed = 3
    const areas = user?.Settings?.[0]?.Areas ?? []
    const displayedAreas = areasExpanded
        ? areas
        : areas.slice(0, maxAreasDisplayed)
    return (
        <div onClick={(e) => {
            if ((e.target as HTMLDivElement).closest('#contact-user-button, #expand-areas-button') == null) {
                navigate(`/${userType.toLowerCase()}/profile/${user.id}`)
            }
        }}
        >
            <div className={'w-100 bg-white p-4 rounded shadow-sm d-flex align-items-center gap-4'} /* style={{ maxHeight: '200px' }} */ >
                {/* <div className={'rounded-circle overflow-hidden'} style={{ height: '5rem', width: '5rem' }}>
                    <img src={'/assets/media/placeholders/user.jpg'} alt="image" className={'h-100 w-100'}/>
                </div> */}
                <Avatar image={user?.Type?.[0]?.Photo ?? undefined} size={'5rem'} />
                <div className={'d-flex flex-column gap-2'} style={{ width: 'calc(100% - 6rem)' }}>
                    <div className={'fw-bold fs-2 text-gray-900 text-truncate d-flex flex-wrap align-items-center gap-2'}>
                        <span>{user?.ContactInfo?.FirstName} {user?.ContactInfo?.FamilyName}</span>
                        {
                            showUserType
                                ? <span className="text-gray-400 fs-4">
                                    ({user?.Category})
                                </span>
                                : <></>
                        }
                    </div>
                    <div className={'d-flex justify-content-between w-100'}>
                        <div className={'d-flex flex-column gap-2'}>
                            {
                                displayedAreas.length > 0
                                    ? <div className="d-flex flex-wrap align-items-center text-gray-400 text-hover-primary gap-1">
                                        <Icon
                                            name={'location_on'}
                                            className={'text-muted fs-4'}
                                        />
                                        {
                                            displayedAreas.map((area) => (
                                                <span className={'badge badge-secondary'} key={nanoid()}>{area}</span>
                                            ))
                                        }
                                        {
                                            areas.length > maxAreasDisplayed
                                                ? <span className={'badge badge-dark'}
                                                    id={'expand-areas-button'}
                                                    onClick={() => {
                                                        setAreasExpanded(!areasExpanded)
                                                    }}
                                                >
                                                    {
                                                        areasExpanded
                                                            ? 'Show less'
                                                            : 'Show more'
                                                    }
                                                </span>
                                                : <></>
                                        }
                                    </div>
                                    : <></>
                            }
                            {/*  new Date().getTime() - new Date(user?.createdAt).getTime() > 1000 * 60 * 60 * 24 * 30
                                ? <div className="d-flex align-items-center text-gray-400 text-hover-primary">
                                    <Icon
                                        name={'work'}
                                        className={'text-muted fs-4 me-1'}
                                    />
                                    Rating {(user as any)?.Settings?.[0]?.Performance ?? 0}
                                </div>
                                : <></>
                            */}
                            {/* (user as any)?.Settings?.[0]?.Membership != null
                                ? <div className="d-flex align-items-center text-gray-400 text-hover-primary">
                                    <Icon
                                        name={'license'}
                                        className={'text-muted fs-4 me-1'}
                                    />
                                    { (user as any)?.Settings?.[0]?.Membership }
                                </div>
                                : <></>
                            */}
                            <div id='contact-user-button' className={'flex-column justify-content-end'}>
                                <div className={`btn btn-sm btn-bso w-fit ${user.id === store.getState().user.currentUser.id ? 'opacity-50' : ''}`}
                                    onClick={() => {
                                        if (user.id !== store.getState().user.currentUser.id) {
                                            setLoading(true)
                                            dispatch(openChatByUserID(user.id))
                                                .then(({ id }: { id: any }) => {
                                                    setLoading(false)
                                                    if (id != null) {
                                                        navigate(`/${userType.toLowerCase()}/chats/${String(id)}`)
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {
                                        loading
                                            ? <span className="indicator-progress d-flex align-items-center">
                                                <Icon
                                                    name={'mail'}
                                                    className={'text-black fs-4 pe-1'}
                                                />
                                                <span>Please wait...</span>
                                                <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                            </span>

                                            : <div className={'d-flex align-items-center'}>
                                                <Icon
                                                    name={'mail'}
                                                    className={'text-black fs-4 pe-1'}
                                                />
                                                <span>Contact</span>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ProfileCard
