import React from 'react'
import { ava_blank } from '../../../urls'

interface AvatarProps {
    image?: string
    size: string
}

const Avatar = ({ image, size }: AvatarProps): JSX.Element => {
    return (
        <div style={{ position: 'relative', width: size, height: size }}>
            <div
                className="rounded-circle overflow-hidden"
                style={{
                    background: `url(${ava_blank}) center center / cover no-repeat`,
                    width: size,
                    height: size,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                }}
            />

            <div
                className="rounded-circle overflow-hidden"
                style={{
                    background: `url(${image ?? ava_blank}) center center / cover no-repeat`,
                    width: size,
                    height: size,
                    position: 'relative',
                    zIndex: 2,
                }}
            />
        </div>
    );
};



export default Avatar
